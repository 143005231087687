import React from "react";
import TestModal from "./TestModal";
import { connect } from "react-redux";
import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import UnauthModal from "./UnauthModal";
import GoingToEventModal from "./GoingToEventModal";
import PaymentUploadModal from "./PaymentUploadModal";
import AttendeeDetailInfo from "./AttendeeDetailModal";

const modalLookup = {
  TestModal,
  LoginModal,
  RegisterModal,
  UnauthModal,
  GoingToEventModal,
  PaymentUploadModal,
  AttendeeDetailInfo
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
