import React from 'react';
import { useDispatch } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import StripeCheckout from 'react-stripe-checkout';
import Numeral from 'numeral';
import Axios from 'axios';
import { confirmPayment } from '../../event/eventActions';

const StripeCheckoutButton = ({ event, attendee }) => {
  const priceForStripe = attendee.orderPrice * 100;
  const URL = 'https://us-central1-cakesshop-41792.cloudfunctions.net/charge/';
  const publishableKey = 'pk_live_y0BY1TybspjpSLur8AFTX26800DIFeXa4U';
  const firestore = useFirestore();
  const dispatch = useDispatch();

  const onToken = token => {
    Axios({
      url: URL,
      method: 'post',
      data: {
        amount: priceForStripe,
        token
      }
    })
      .then(response => {
        if (response.data.body) {
          dispatch(
            confirmPayment(
              { firestore },
              event,
              attendee.id,
              attendee.displayName
            )
          );
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <StripeCheckout
      label="Pay Now"
      name="Dwiz-Vent"
      currency="IDR"
      email={attendee.email}
      locale="auto"
      image="https://svgshare.com/i/CUz.svg"
      description={`Your order : ${attendee.orderQuantity} x ${Numeral(
        attendee.orderPrice / attendee.orderQuantity
      ).format('0,0')} = ${Numeral(attendee.orderPrice).format('0,0')}`}
      amount={priceForStripe}
      panelLabel="Pay Now"
      token={onToken}
      stripeKey={publishableKey}
    />
  );
};

export default StripeCheckoutButton;
