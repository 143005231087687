import React, { useCallback } from 'react';
import { Form, Segment, Button, Label, Header } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../../app/common/form/TextInput';
import { registerUser } from '../authActions';

const RegisterForm = ({ handleSubmit, error, submitting }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const handleregister = useCallback(
    user => {
      return dispatch(registerUser({ firebase, firestore }, user));
    },
    [firebase, firestore, dispatch]
  );
  return (
    <div>
      <Form
        size="large"
        autoComplete="off"
        onSubmit={handleSubmit(handleregister)}
      >
        <Segment>
          <Header sub color="orange" content="Display Name" />
          <Field
            name="displayName"
            type="text"
            component={TextInput}
            placeholder="Known As"
          />
          <Header sub color="orange" content="Email" />
          <Field
            name="email"
            type="text"
            component={TextInput}
            placeholder="Email"
          />
          <Header sub color="orange" content="Password" />
          <Field
            name="password"
            autoComplete="new-password"
            type="password"
            component={TextInput}
            placeholder="Password"
          />
          {error && (
            <Label basic color="red">
              {error}
            </Label>
          )}
          <Button
            loading={submitting}
            disabled={submitting}
            fluid
            size="large"
            color="teal"
          >
            Register
          </Button>
        </Segment>
      </Form>
    </div>
  );
};

export default reduxForm({ form: 'registerForm' })(RegisterForm);
