import { closeModal } from "../modals/modalActions";
import { SubmissionError, reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from "../async/asyncActions";

export const login = ({ firebase }, user) => async dispatch => {
  try {
    const userSignIn = await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password);
    dispatch(closeModal());
    toastr.success("Success", `Welcome, ${userSignIn.user.displayName}`);
  } catch (error) {
    console.log(error);
    toastr.error("Error", "Something went wrong, problem with sign in");
    throw new SubmissionError({
      _error: error.message
    });
  }
};

export const registerUser = (
  { firebase, firestore },
  user
) => async dispatch => {
  try {
    let createdUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);

    await createdUser.user.updateProfile({
      displayName: user.displayName,
      email: user.email
    });
    let newUser = {
      displayName: user.displayName,
      email: user.email,
      createdAt: firestore.FieldValue.serverTimestamp()
    };
    await firestore.set(`users/${createdUser.user.uid}`, { ...newUser });
    dispatch(closeModal());
    toastr.success("Success", `Welcome, ${user.displayName}`);
  } catch (error) {
    console.log(error);
    toastr.error("Error", "Something went wrong, problem with sign out");
    throw new SubmissionError({
      _error: error.message
    });
  }
};

export const socialLogin = (
  { firebase, firestore },
  selectedProvider
) => async dispatch => {
  try {
    dispatch(closeModal());
    let user = await firebase.login({
      provider: selectedProvider,
      type: "popup"
    });

    if (user.additionalUserInfo.isNewUser) {
      await firestore.set(`users/${user.user.uid}`, {
        displayName: user.profile.displayName,
        photoURL: user.profile.avatarUrl,
        createdAt: firestore.FieldValue.serverTimestamp(),
        email: user.additionalUserInfo.profile.email
      });
    }
    toastr.success("Success", `Welcome, ${user.profile.displayName}`);
  } catch (error) {
    console.log(error);
    toastr.error("Error", "Something went wrong, problem with social login");
  }
};

export const updatePassword = ({ firebase }, creds) => async dispatch => {
  const user = firebase.auth().currentUser;
  try {
    dispatch(asyncActionStart());

    await user.updatePassword(creds.newPassword1);

    await dispatch(reset("account"));
    toastr.success("Success", "Your password has been updated");
    dispatch(asyncActionFinish());
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error("Error", "Problem updating the password");
    throw new SubmissionError({
      _error: error.message
    });
  }
};
