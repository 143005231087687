import React from 'react';
import { Card, Grid, Header, Segment, Tab } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Img from 'react-image';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import LazyLoad from 'react-lazyload';
import { useMediaQuery } from 'react-responsive';

const UserDetailedEvents = ({
  userHostEvent,
  allEvents,
  userFutureEvent,
  userPastEvent,
  loading
}) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const elementName = useSelector(state => state.async.elementName, []);
  let queryColumn = isTabletOrMobileDevice ? '2' : '4';
  const panes = [
    {
      menuItem: 'All',
      render: () => (
        <Tab.Pane attached={false}>
          {' '}
          <Card.Group itemsPerRow={queryColumn}>
            {allEvents &&
              allEvents.map(event => (
                <LazyLoad key={event.id} height={170}>
                  <Card
                    as={Link}
                    to={`/events/${event.id}`}
                    style={{ overflow: 'hidden' }}
                  >
                    <Img
                      src={event.eventPhotoURL}
                      loader={<LoadingComponent />}
                      unloader={<span>Image not found</span>}
                      style={{ width: '100%' }}
                    />

                    <Card.Content>
                      <Card.Header textAlign="center">
                        {event.title}
                      </Card.Header>
                      <Card.Meta textAlign="center">
                        <div style={{ color: 'gray' }}>
                          {format(
                            event.date && event.date.toDate(),
                            'dd LLL yyyy'
                          )}
                        </div>
                        <div style={{ color: 'gray' }}>
                          {format(event.date && event.date.toDate(), 'h:mm a')}
                        </div>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </LazyLoad>
              ))}
          </Card.Group>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Past',
      render: () => (
        <Tab.Pane attached={false}>
          <Card.Group itemsPerRow={queryColumn}>
            {userPastEvent &&
              userPastEvent.map(event => (
                <LazyLoad key={event.id} height={170}>
                  <Card
                    as={Link}
                    to={`/events/${event.id}`}
                    style={{ overflow: 'hidden' }}
                  >
                    <Img
                      src={event.eventPhotoURL}
                      loader={<LoadingComponent />}
                      unloader={<span>Image not found</span>}
                      style={{ width: '100%' }}
                    />

                    <Card.Content>
                      <Card.Header textAlign="center">
                        {event.title}
                      </Card.Header>
                      <Card.Meta textAlign="center">
                        <div style={{ color: 'gray' }}>
                          {format(
                            event.date && event.date.toDate(),
                            'dd LLL yyyy'
                          )}
                        </div>
                        <div style={{ color: 'gray' }}>
                          {format(event.date && event.date.toDate(), 'h:mm a')}
                        </div>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </LazyLoad>
              ))}
          </Card.Group>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Future',
      render: () => (
        <Tab.Pane attached={false}>
          {' '}
          <Card.Group itemsPerRow={queryColumn}>
            {userFutureEvent &&
              userFutureEvent.map(event => (
                <LazyLoad key={event.id} height={170}>
                  <Card
                    as={Link}
                    to={`/events/${event.id}`}
                    style={{ overflow: 'hidden' }}
                  >
                    <Img
                      src={event.eventPhotoURL}
                      loader={<LoadingComponent />}
                      unloader={<span>Image not found</span>}
                      style={{ width: '100%' }}
                    />

                    <Card.Content>
                      <Card.Header textAlign="center">
                        {event.title}
                      </Card.Header>
                      <Card.Meta textAlign="center">
                        <div style={{ color: 'gray' }}>
                          {format(
                            event.date && event.date.toDate(),
                            'dd LLL yyyy'
                          )}
                        </div>
                        <div style={{ color: 'gray' }}>
                          {format(event.date && event.date.toDate(), 'h:mm a')}
                        </div>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </LazyLoad>
              ))}
          </Card.Group>
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Hosted',
      render: () => (
        <Tab.Pane attached={false}>
          <Card.Group itemsPerRow={queryColumn}>
            {userHostEvent &&
              userHostEvent.map(event => (
                <LazyLoad key={event.id} height={170}>
                  <Card
                    as={Link}
                    to={`/events/${event.id}`}
                    style={{ overflow: 'hidden' }}
                  >
                    <Img
                      src={event.eventPhotoURL}
                      loader={<LoadingComponent />}
                      unloader={<span>Image not found</span>}
                      style={{ width: '100%' }}
                    />

                    <Card.Content>
                      <Card.Header textAlign="center">
                        {event.title}
                      </Card.Header>
                      <Card.Meta textAlign="center">
                        <div style={{ color: 'gray' }}>
                          {format(
                            event.date && event.date.toDate(),
                            'dd LLL yyyy'
                          )}
                        </div>
                        <div style={{ color: 'gray' }}>
                          {format(event.date && event.date.toDate(), 'h:mm a')}
                        </div>
                      </Card.Meta>
                    </Card.Content>
                  </Card>
                </LazyLoad>
              ))}
          </Card.Group>
        </Tab.Pane>
      )
    }
  ];
  return (
    <Grid.Column mobile={16} tablet={16} computer={12}>
      <Segment attached loading={loading && !elementName}>
        <Header icon="calendar" content="Events" />
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Segment>
    </Grid.Column>
  );
};

export default UserDetailedEvents;
