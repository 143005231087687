import React, { Fragment } from "react";
import { Grid, Header, Icon, Item, List, Segment } from "semantic-ui-react";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";

const UserDetailedDescription = ({ profile }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  let createdAt;
  if (profile.createdAt) {
    createdAt = format(profile.createdAt.toDate(), "d LLL yyyy");
  }
  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid.Column width={16}>
          <Segment>
            <Grid>
              <Grid.Column width={16}>
                <Header
                  icon="smile"
                  content={`About  ${
                    profile.displayName ? profile.displayName : ""
                  }`}
                />
                <p>
                  I am a:{" "}
                  <strong style={{ marginLeft: "5px" }}>
                    {profile.occupation || ""}
                  </strong>
                </p>
                <p>
                  Originally from <strong>{profile.origin || ""}</strong>
                </p>
                <p>
                  Member Since:
                  <strong style={{ marginLeft: "5px" }}>{createdAt}</strong>
                </p>
                <p>
                  Description:
                  <strong style={{ marginLeft: "5px" }}>
                    {profile.about || ""}
                  </strong>
                </p>
              </Grid.Column>
              <Grid.Column width={16}>
                <Header icon="heart outline" content="Interests" />
                {profile.interests ? (
                  <List>
                    {profile.interests &&
                      profile.interests.map((interest, index) => (
                        <Item key={index}>
                          <Icon name="heart" />
                          <Item.Content>{interest}</Item.Content>
                        </Item>
                      ))}
                  </List>
                ) : (
                  <p>No interests</p>
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      )}
      {isDesktopOrLaptop && (
        <Grid.Column width={12}>
          <Segment>
            <Grid columns={2}>
              <Grid.Column width={10}>
                <Header
                  icon="smile"
                  content={`About  ${
                    profile.displayName ? profile.displayName : ""
                  }`}
                />
                <p>
                  I am a:{" "}
                  <strong style={{ marginLeft: "5px" }}>
                    {profile.occupation || ""}
                  </strong>
                </p>
                <p>
                  Originally from <strong>{profile.origin || ""}</strong>
                </p>
                <p>
                  Member Since:
                  <strong style={{ marginLeft: "5px" }}>{createdAt}</strong>
                </p>
                <p>
                  Description:
                  <strong style={{ marginLeft: "5px" }}>
                    {profile.about || ""}
                  </strong>
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header icon="heart outline" content="Interests" />
                {profile.interests ? (
                  <List>
                    {profile.interests &&
                      profile.interests.map((interest, index) => (
                        <Item key={index}>
                          <Icon name="heart" />
                          <Item.Content>{interest}</Item.Content>
                        </Item>
                      ))}
                  </List>
                ) : (
                  <p>No interests</p>
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      )}
    </Fragment>
  );
};

export default UserDetailedDescription;
