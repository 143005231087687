import React from 'react';
import { Grid, Header, Segment, Card } from 'semantic-ui-react';
import Img from 'react-image';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import LazyLoad from 'react-lazyload';
import {useMediaQuery} from 'react-responsive'
const UserDetailedPhotos = ({ photos }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  return (
    <Grid.Column width={isDesktopOrLaptop ? 12 : 16}>
      <Segment attached>
        <Header icon="image" content="Photos" />
        <Card.Group doubling itemsPerRow={4}>
          {photos &&
            photos.map(photo => (
              <LazyLoad key={photo.id} height={140}>
                <Card>
                  <Img
                    src={photo.url}
                    loader={<LoadingComponent />}
                    unloader={<span>Image not found</span>}
                    style={{ width: '100%' }}
                  />
                </Card>
              </LazyLoad>
            ))}
        </Card.Group>
      </Segment>
    </Grid.Column>
  );
};

export default UserDetailedPhotos;
