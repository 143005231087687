import React from 'react';
import ReactLazy from 'react-lazyload';
import Img from 'react-image';
import { Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const PersonCard = ({ user }) => {
  return (
    <ReactLazy height={125}>
      <Card
        as={Link}
        to={`/profile/${user.id}`}
        style={{ overflowX: 'hidden' }}
      >
        <Img
          src={user.photoURL || '/assets/user.png'}
          loader={<LoadingComponent />}
          style={{ width: '100%' }}
        />

        <Card.Content textAlign="center">
          <Card.Header
            style={{ color: 'darkBlue' }}
            content={user.displayName}
          />
        </Card.Content>
        <Card.Meta textAlign="center">
          <h4
            style={{
              color: 'orangeRed',
              marginBottom: '5px',
              marginTop: '-5px',
              fontSize: '13px'
            }}
          >
            {user.occupation || ''}
          </h4>

          <span
            style={{
              color: 'green',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            {user.city && user.city.substring(0, user.city.indexOf(','))}
          </span>
        </Card.Meta>
      </Card>
    </ReactLazy>
  );
};

export default PersonCard;
