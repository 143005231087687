import React, { Fragment, useState, useCallback } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Grid, Header, Select } from 'semantic-ui-react';
import {
  combineValidators,
  isRequired,
  composeValidators,
  isNumeric
} from 'revalidate';
import TextInput from '../../../app/common/form/TextInput';

import TextArea from '../../../app/common/form/TextArea';
import NumberFormat from 'react-number-format';
import { goingToEvent } from '../userActions';
import { closeModal } from '../../modals/modalActions';

const validate = combineValidators({
  paymentMethod: isRequired('Payment method'),
  quantity: composeValidators(
    isNumeric({ message: 'Incorrect field value, number is required' }),
    isRequired({ message: 'Please enter a valid quantiy' })
  )()
});

const UserGoingToEvent = ({
  event,
  handleSubmit,
  invalid,
  pristine,
  submitting
}) => {
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector(state => state.async.loading);

  const paymentDefaultAvaliable = [
    { key: 'cod', text: 'COD', value: 'cod' },
    { key: 'bankTransfer', text: 'Bank Transfer', value: 'bankTransfer' },
    {
      key: 'creditCard',
      text: totalOrderPrice <= 50000 ? 'Credit Card > 50.000' : 'Credit Card',
      value: 'creditCard',
      disabled: totalOrderPrice <= 50000
    }
  ];
  let paymentAvaliable = [];
  if (event.paymentMethod.length > 0) {
    paymentAvaliable = paymentDefaultAvaliable.filter(pdavaliable =>
      event.paymentMethod.includes(pdavaliable.value)
    );
  }

  const handleQuantity = quantity => {
    setTotalOrderPrice(() => quantity.target.value * event.price);
    if (
      quantity.target.value * event.price <= 50000 &&
      paymentMethod === 'creditCard'
    ) {
      setPaymentMethod('');
    }
  };

  const handlePaymentMethod = (e, data) => {
    setPaymentMethod(data.value);
  };

  const userGoingToEvent = useCallback(
    async userOrder => {
      userOrder.paymentMethod = paymentMethod;
      if (totalOrderPrice > 0) {
        if (paymentMethod === 'creditCard') {
          userOrder.orderPrice = totalOrderPrice * 1.045;
        } else {
          userOrder.orderPrice = totalOrderPrice;
        }
      }
      try {
        await dispatch(goingToEvent(event, userOrder));
        dispatch(reset('userGoingToEventForm'));
        dispatch(closeModal());
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, event, totalOrderPrice, paymentMethod]
  );
  const limitQty = event.lockOutMinPeople === 'yes' && totalOrderPrice > event.price;
  return (
    <Fragment>
      <Form autoComplete="off" onSubmit={handleSubmit(userGoingToEvent)}>
        <Header sub color="orange" content="Payment Method" />
        <Select
          fluid
          name="paymentMethod"
          options={paymentAvaliable}
          onChange={handlePaymentMethod}
          value={paymentMethod}
          placeholder="Select avaliable payment method"
        />
        {paymentMethod === 'bankTransfer' && (
          <div style={{ fontSize: '12px' }}>
            Please transfer to account bank :{' '}
            <div style={{ fontWeight: 'bolder' }}>
              BCA : 0320 60 6268{' '}
              <span style={{ marginLeft: '0.5em' }}>a.n. Stevanus Oetama</span>
            </div>
            <div style={{ fontWeight: 'bolder' }}>
              BNI : 0345 78 9570{' '}
              <span style={{ marginLeft: '0.5em' }}>a.n. Dewi Sartika</span>
            </div>
          </div>
        )}

        <Header sub color="orange" content="Quantity" />
        <Field
          name="quantity"
          type="number"
          component={TextInput}
          onChange={handleQuantity}
          placeholder="Your order quantity"
        />
        {limitQty && (
          <span style={{ color: 'red', fontSize: '13px' }}>
            Please decrease your order quantity
          </span>
        )}
        {totalOrderPrice > 0 && (
          <Grid textAlign="center" style={{ marginBottom: '10px' }}>
            <Grid.Column>
              <span style={{ fontWeight: 'bold', color: 'green' }}>
                {' '}
                Total Order Price:{' '}
              </span>
              {paymentMethod !== 'creditCard' && (
                <NumberFormat
                  style={{ fontWeight: 'bold', color: 'green' }}
                  value={totalOrderPrice}
                  prefix="Rp "
                  displayType={'text'}
                  thousandSeparator={true}
                  thousandsGroupStyle="thousand"
                />
              )}
              {paymentMethod === 'creditCard' && (
                <Fragment>
                  <NumberFormat
                    style={{ fontWeight: 'bold', color: 'green' }}
                    value={totalOrderPrice * 1.045}
                    prefix="Rp "
                    displayType={'text'}
                    thousandSeparator={true}
                    thousandsGroupStyle="thousand"
                  />
                  <div style={{ fontSize: '10px', color: 'red' }}>
                    *include charges
                  </div>
                </Fragment>
              )}
            </Grid.Column>
          </Grid>
        )}
        <Header sub color="orange" content="Order Notes" />
        <Field
          name="orderDescription"
          type="text"
          component={TextArea}
          rows={3}
          placeholder="Your order notes"
        />
        <Button
          disabled={
            invalid || pristine || loading || paymentMethod === '' || limitQty
          }
          loading={submitting}
          size="large"
          fluid
          color="teal"
        >
          Submit
        </Button>
      </Form>
    </Fragment>
  );
};

export default reduxForm({
  form: 'userGoingToEventForm',
  validate,
  destroyOnUnmount: false
})(UserGoingToEvent);
