import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Grid, Segment, Card, Tab } from "semantic-ui-react";
import PersonCard from "./PersonCard";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useMediaQuery } from "react-responsive";

const PeopleDashboard = () => {
  let followingsId = [];
  let followersId = [];
  let usersFollowing = [];
  let usersFollower = [];

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  let queryColumn = isTabletOrMobileDevice ? "2" : "6";
  const auth = useSelector(state => state.firebase.auth);
  const followingQuery = useMemo(
    () => ({
      collection: "users",
      doc: auth.uid,
      subcollections: [{ collection: "following" }],
      storeAs: "following"
    }),
    [auth.uid]
  );
  const followerQuery = useMemo(
    () => ({
      collection: "users",
      doc: auth.uid,
      subcollections: [{ collection: "followers" }],
      storeAs: "followers"
    }),
    [auth.uid]
  );

  const userQuery = useMemo(
    () => ({
      collection: "users",
      storeAs: "allUsers"
    }),
    []
  );

  useFirestoreConnect(followerQuery);
  useFirestoreConnect(followingQuery);
  useFirestoreConnect(userQuery);
  const followers = useSelector(state => state.firestore.ordered.followers, []);
  const followings = useSelector(
    state => state.firestore.ordered.following,
    []
  );
  const allUsers = useSelector(state => state.firestore.ordered.allUsers, []);
  const allUsersSort =
    allUsers &&
    allUsers
      .filter(user => user.id !== auth.uid)
      .sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase()
          ? 1
          : b.displayName.toLowerCase() > a.displayName.toLowerCase()
          ? -1
          : 0
      );

  if (followings && followings.length > 0) {
    followings.map(following => {
      return followingsId.push(following.id);
    });
    if (allUsers && allUsers.length > 0) {
      usersFollowing = allUsers.filter(user => followingsId.includes(user.id));
    }
  }
  if (followers && followers.length > 0) {
    followers.map(follower => {
      return followersId.push(follower.id);
    });
    if (allUsers && allUsers.length > 0) {
      usersFollower = allUsers.filter(user => followersId.includes(user.id));
    }
  }

  const panes = [
    {
      menuItem: "All Users",
      render: () =>
        allUsersSort && allUsersSort.length > 0 ? (
          <Tab.Pane attached={false}>
            <Grid>
              <Grid.Column width={16}>
                <Segment>
                  <Card.Group itemsPerRow={queryColumn}>
                    {allUsersSort &&
                      allUsersSort.map(follower => (
                        <PersonCard key={follower.id} user={follower} />
                      ))}
                  </Card.Group>
                </Segment>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
        ) : (
          <LoadingComponent />
        )
    },
    {
      menuItem: "Followers",
      render: () =>
        usersFollower.length > 0 ? (
          <Tab.Pane attached={false}>
            <Grid>
              <Grid.Column width={16}>
                <Segment>
                  <Card.Group itemsPerRow={queryColumn}>
                    {usersFollower &&
                      usersFollower.map(follower => (
                        <PersonCard key={follower.id} user={follower} />
                      ))}
                  </Card.Group>
                </Segment>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
        ) : (
          <div>You do not have followers yet</div>
        )
    },
    {
      menuItem: "Followings",
      render: () =>
        usersFollowing.length > 0 ? (
          <Tab.Pane attached={false}>
            {" "}
            <Grid>
              <Grid.Column width={16}>
                <Segment>
                  <Card.Group itemsPerRow={queryColumn}>
                    {usersFollowing &&
                      usersFollowing.map(follower => (
                        <PersonCard key={follower.id} user={follower} />
                      ))}
                  </Card.Group>
                </Segment>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
        ) : (
          <div>You do not have followings yet</div>
        )
    }
  ];
  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      style={{ width: isTabletOrMobileDevice ? "100%" : "92%" }}
    />
  );
};

export default PeopleDashboard;
