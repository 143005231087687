import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { Segment, Header, Divider, Grid, Button } from 'semantic-ui-react';
import DropzoneInput from '../../user/Settings/Photos/DropZoneInput';
import CropperInput from '../../user/Settings/Photos/CropperInput';
import {
  setMainEventPhoto,
  deletePhotoEvent,
  uploadEventImage
} from '../eventActions';
import EventPhotos from './EventPhotos';
import { useMediaQuery } from 'react-responsive';
import { toastr } from 'react-redux-toastr';

const EventSettingsPhotos = ({ photos, event }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  // mapstate equivalent

  const loading = useSelector(state => state.async.loading, []);
  const elementName = useSelector(state => state.async.elementName, []);

  // actions equivalent
  const [files, setFiles] = useState([]);
  const [cropResult, setCropResult] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
      URL.revokeObjectURL(cropResult);
    };
  }, [files, cropResult]);

  const handleUploadImage = useCallback(async () => {
    try {
      await dispatch(
        uploadEventImage(
          { firebase, firestore },
          image,
          files[0].name,
          event.id
        )
      );
      handleCancelCrop();
    } catch (error) {
      console.log(error);
      toastr.error('Error', error);
    }
  }, [dispatch, firebase, firestore, image, files, event.id]);

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
    setCropResult('');
  };

  const handleDeletePhoto = useCallback(
    async (photo, eventId) => {
      try {
        await dispatch(
          deletePhotoEvent({ firebase, firestore }, photo, eventId)
        );
      } catch (error) {}
    },
    [dispatch, firebase, firestore]
  );

  const handleSetMainPhoto = useCallback(
    async (photo, eventId, photoId) => {
      try {
        await dispatch(
          setMainEventPhoto({ firebase, firestore }, photo, eventId, photoId)
        );
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, firebase, firestore]
  );

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Fragment>
          {isPortrait && (
            <Grid style={{ marginBottom: '5px' }}>
              <Grid.Row />
              <Grid.Column width={16} textAlign="center">
                <Header color="teal" sub content="Step 1 - Add Photo" />
                <DropzoneInput setFiles={setFiles} />
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <Header sub color="teal" content="Step 2 - Resize image" />
                {files && files.length > 0 && (
                  <div style={{ fontSize: '11px', color: 'red' }}>
                    *if image size larger than 1mb, you may have lagging when
                    cropping
                  </div>
                )}
                {files.length > 0 && (
                  <CropperInput
                    aspectRatio={290 / 200}
                    imagePreview={files[0].preview}
                    filesType={files[0].type}
                    setImage={setImage}
                    setCropResult={setCropResult}
                  />
                )}
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <Header
                  sub
                  color="teal"
                  content="Step 3 - Preview & Upload"
                  style={{ marginBottom: '7px' }}
                />
                {files.length > 0 && (
                  <Fragment>
                    <div
                      className="img-preview center-image-preview"
                      style={{
                        minHeight: '200px',
                        minWidth: '290px',
                        overflow: 'hidden'
                      }}
                    />

                    <Button.Group style={{ marginTop: '5px' }}>
                      <Button
                        loading={loading}
                        disabled={loading}
                        onClick={handleUploadImage}
                        style={{ width: '145px' }}
                        positive
                        icon="check"
                      />
                      <Button
                        disabled={loading}
                        onClick={handleCancelCrop}
                        style={{ width: '145px' }}
                        icon="close"
                      />
                    </Button.Group>
                  </Fragment>
                )}
              </Grid.Column>
            </Grid>
          )}

          {!isPortrait && (
            <div
              style={{
                fontSize: '13px',
                color: 'red',
                marginBottom: '10px',
                textAlign: 'center'
              }}
            >
              Please use portrait mode
            </div>
          )}

          <Divider />
          <EventPhotos
            event={event}
            photos={photos}
            deletePhoto={handleDeletePhoto}
            setMainPhoto={handleSetMainPhoto}
            loading={loading}
            elementName={elementName}
          />
        </Fragment>
      )}

      {isDesktopOrLaptop && (
        <Segment>
          <Header dividing size="large" content="Your Photos" />
          <Grid>
            <Grid.Row />
            <Grid.Column width={4}>
              <Header color="teal" sub content="Step 1 - Add Photo" />
              <DropzoneInput setFiles={setFiles} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color="teal" content="Step 2 - Resize image" />
              {files.length > 0 && (
                <CropperInput
                  aspectRatio={290 / 200}
                  imagePreview={files[0].preview}
                  filesType={files[0].type}
                  setImage={setImage}
                  setCropResult={setCropResult}
                />
              )}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color="teal" content="Step 3 - Preview & Upload" />
              {files.length > 0 && (
                <Fragment>
                  <div
                    className="img-preview"
                    style={{
                      minHeight: '200px',
                      minWidth: '290px',
                      overflow: 'hidden'
                    }}
                  />
                  <Button.Group style={{ marginTop: '5px' }}>
                    <Button
                      loading={loading}
                      disabled={loading}
                      onClick={handleUploadImage}
                      style={{ width: '145px' }}
                      positive
                      icon="check"
                    />
                    <Button
                      disabled={loading}
                      onClick={handleCancelCrop}
                      style={{ width: '145px' }}
                      icon="close"
                    />
                  </Button.Group>
                </Fragment>
              )}
            </Grid.Column>
          </Grid>

          <Divider />
          <EventPhotos
            event={event}
            photos={photos}
            deletePhoto={handleDeletePhoto}
            setMainPhoto={handleSetMainPhoto}
            loading={loading}
            elementName={elementName}
          />
        </Segment>
      )}
    </Fragment>
  );
};
export default EventSettingsPhotos;
