/*global google*/
import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { useFirebase, useFirestore } from 'react-redux-firebase';

import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  composeValidators,
  combineValidators,
  isNumeric,
  createValidator,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import { Form, Segment, Button, Grid, Header } from 'semantic-ui-react';
import { createEvent } from '../eventActions';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import DateInput from '../../../app/common/form/DateInput';
import PlaceInput from '../../../app/common/form/PlaceInput';
import DropZoneInput from '../../user/Settings/Photos/DropZoneInput';
import CropperInput from '../../user/Settings/Photos/CropperInput';
import { createNumberMask } from 'redux-form-input-masks';
import { useMediaQuery } from 'react-responsive';
import { toastr } from 'react-redux-toastr';
import RadioInput from '../../../app/common/form/RadioInput';

const isLessThan = (n) =>
  createValidator(
    (message) => (value) => {
      if (value && Number(value) < n) {
        return message;
      }
    },
    (field) => `${field} must be greater than ${n}`
  );
const validate = combineValidators({
  title: isRequired({ message: 'The event title is required' }),
  category: isRequired({ message: 'Please provide a category' }),
  description: composeValidators(
    isRequired({ message: 'Please enter a description' }),
    hasLengthGreaterThan(4)({
      message: 'Description needs to be at least 5 characters',
    })
  )(),
  schedule: isRequired('schedule'),
  minPeople: composeValidators(
    isRequired({ message: 'Please enter a minimal people' }),
    isNumeric({ message: 'Must be a valid number' }),
    isLessThan(1)({
      message: 'Minimal people at least 1 people',
    })
  )(),
  lockOutMinPeople: isRequired('lockOutMinPeople'),
  lockOutOrCancelledTime: isRequired('lockOutOrCancelledTime'),
  city: isRequired('city'),
  venue: isRequired('venue'),
  date: isRequired('date'),
});

const currencyMask = createNumberMask({
  prefix: 'Rp ',
  suffix: ' per item',
  decimalPlaces: 0,
  locale: 'id-ID',
});

const category = [
  { key: 'commercial', text: 'Commercial', value: 'commercial' },
  { key: 'hangOut', text: 'Hang Out', value: 'hangOut' },
];
const paymentAvaliable = [
  { key: 'cod', text: 'COD', value: 'cod' },
  { key: 'bankTransfer', text: 'Bank Transfer', value: 'bankTransfer' },
  { key: 'creditCard', text: 'Credit Card', value: 'creditCard' },
];

const EventForm = ({
  change,
  history,
  invalid,
  submitting,
  pristine,
  handleSubmit,
}) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)',
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)',
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const dispatch = useDispatch();
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [cityLatLng, setCityLatLng] = useState({});
  const [venueLatLng, setVenueLatLng] = useState({});
  const [selectedCategory, setCategory] = useState('');

  const [files, setFiles] = useState([]);
  const [cropResult, setCropResult] = useState('');
  const [image, setImage] = useState(null);

  const loading = useSelector((state) => state.async.loading, []);

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      URL.revokeObjectURL(cropResult);
    };
  }, [files, cropResult]);

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
    setCropResult('');
  };

  const handleCitySelect = (selectedCity) => {
    geocodeByAddress(selectedCity)
      .then((results) => getLatLng(results[0]))
      .then((latlng) => {
        setCityLatLng(latlng);
      })
      .then(() => {
        change('city', selectedCity);
      });
  };

  const handleVenueSelect = (selectedVenue) => {
    geocodeByAddress(selectedVenue)
      .then((results) => getLatLng(results[0]))
      .then((latlng) => {
        setVenueLatLng(latlng);
      })
      .then(() => {
        change('venue', selectedVenue);
      });
  };

  const handleSelectCategory = (selectCategory) => {
    if (selectCategory === 'commercial') {
      setCategory(selectCategory);
    } else {
      setCategory('');
    }
  };

  const handleFormSubmit = async (values) => {
    if (
      values.category === 'commercial' &&
      (values.price === 0 || !values.price)
    ) {
      toastr.info('Info', 'Price can not be null');
      return;
    }
    if (values.category === 'commercial' && !values.paymentMethod) {
      toastr.info('Info', 'Please select payment method');
      return;
    }
    if (values.category === 'commercial' && !values.schedule) {
      toastr.info('Info', 'Schedule required');
      return;
    }
    if (values.category !== 'commercial') {
      values.price = 0;
      values.paymentMethod = [];
    }
    if (values.schedule === 'noSchedule') {
      values.reschedule = false;
    } else if (values.schedule === 'sn-rb-jm') {
      values.reschedule = true;
    } else if (values.schedule === 'sl-km-sb') {
      values.reschedule = true;
    } else if (values.schedule === 'everyWeek') {
      values.reschedule = true;
    }

    values.venueLatLng = venueLatLng;
    values.cancelled = false;
    values.locked = false;
    values.finished = false;
    values.cloudNotes = '';
    const createdEvent = await dispatch(
      createEvent({ firebase, firestore }, values, image, files[0].name)
    );
    history.push(`/events/${createdEvent.id}`);
  };

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid>
          <Grid.Column width={16}>
            <Segment>
              <Form
                onSubmit={handleSubmit(handleFormSubmit)}
                autoComplete='off'
              >
                <Header sub color='teal' content='Event Name' />
                <Field
                  name='title'
                  type='text'
                  component={TextInput}
                  placeholder='Give your event a name'
                />
                <Header sub color='teal' content='Event Category' />
                <Field
                  name='category'
                  type='text'
                  options={category}
                  component={SelectInput}
                  onChange={handleSelectCategory}
                  placeholder='What is your event type'
                />
                {isPortrait && (
                  <Grid style={{ marginBottom: '5px' }}>
                    <Grid.Row />
                    <Grid.Column width={16} textAlign='center'>
                      <Header color='teal' sub content='Step 1 - Add Photo' />
                      <DropZoneInput setFiles={setFiles} />
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='center'>
                      <Header
                        sub
                        color='teal'
                        content='Step 2 - Resize image'
                      />

                      {files && files.length > 0 && (
                        <div style={{ fontSize: '11px', color: 'red' }}>
                          *if image size larger than 1mb, you may have lagging
                          when cropping
                        </div>
                      )}

                      {files.length > 0 && (
                        <CropperInput
                          aspectRatio={290 / 200}
                          imagePreview={files[0].preview}
                          filesType={files[0].type}
                          setImage={setImage}
                          setCropResult={setCropResult}
                        />
                      )}
                    </Grid.Column>

                    <Grid.Column width={16} textAlign='center'>
                      <Header
                        sub
                        color='teal'
                        content='Step 3 - Preview'
                        style={{ marginBottom: '7px' }}
                      />
                      {files.length > 0 && (
                        <Fragment>
                          <div
                            className='img-preview center-image-preview'
                            style={{
                              minHeight: '200px',
                              minWidth: '290px',
                              overflow: 'hidden',
                            }}
                          />

                          <Button
                            onClick={handleCancelCrop}
                            style={{ width: '294px' }}
                            icon='close'
                          />
                        </Fragment>
                      )}
                    </Grid.Column>
                  </Grid>
                )}

                {!isPortrait && (
                  <div
                    style={{
                      fontSize: '13px',
                      color: 'red',
                      marginBottom: '10px',
                      textAlign: 'center',
                    }}
                  >
                    Please use portrait mode
                  </div>
                )}

                {selectedCategory && selectedCategory === 'commercial' && (
                  <Fragment>
                    <Header sub color='teal' content='Price' />
                    <Field
                      name='price'
                      type='text'
                      component={TextInput}
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '8px',
                      }}
                      {...currencyMask}
                      placeholder='Price per item'
                    />
                    <Header sub color='teal' content='Payment Method' />
                    <Field
                      name='paymentMethod'
                      type='text'
                      options={paymentAvaliable}
                      multiple={true}
                      component={SelectInput}
                      style={{
                        width: '100%',
                        marginTop: '8px',
                        marginBottom: '17px',
                      }}
                      placeholder='Select avaliable payment method'
                    />
                    <Header sub color='teal' content='Min People' />
                    <Field
                      name='minPeople'
                      component={TextInput}
                      type='number'
                      placeholder='Minimal People'
                    />
                    <Header
                      sub
                      color='teal'
                      content='Automatic lockout when minimal people fulfilled'
                    />
                    <Form.Group style={{ marginTop: '11px' }}>
                      <Field
                        name='lockOutMinPeople'
                        component={RadioInput}
                        type='radio'
                        value='no'
                        label='No'
                      />
                      <Field
                        name='lockOutMinPeople'
                        component={RadioInput}
                        type='radio'
                        value='yes'
                        label='Yes'
                      />
                    </Form.Group>

                    <Form.Group inline style={{ marginTop: '19px' }}>
                      <Header sub color='teal' content='Schedule ? ' />
                      <div style={{ marginLeft: '1.3em' }}>
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='noSchedule'
                          label='No'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='sn-rb-jm'
                          label='SN-RB-JM'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='sl-km-sb'
                          label='SL-KM-SB'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='everyWeek'
                          label='Every week'
                        />
                      </div>
                    </Form.Group>
                    <Header
                      sub
                      color='teal'
                      content='Automatic LockOut or Cancelled event'
                      style={{ marginBottom: '1em' }}
                    />
                    <Form.Group>
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='oneHour'
                        label='1 hour'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twoHours'
                        label='2 hours'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='fiveHours'
                        label='5 hours'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twelveHours'
                        label='12 hours'
                      />

                      <div style={{ marginTop: '2em' }}></div>
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twentyFourHours'
                        label='24 hours'
                      />
                    </Form.Group>
                  </Fragment>
                )}
                <Header sub color='teal' content='Event Description' />
                <Field
                  name='description'
                  type='text'
                  rows={3}
                  component={TextArea}
                  placeholder='Tell us about your event'
                />
                <Header sub color='teal' content='Event Location City' />
                <Field
                  name='city'
                  type='text'
                  component={PlaceInput}
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'id' },
                  }}
                  placeholder='Event city'
                  onSelect={handleCitySelect}
                />
                <Header
                  sub
                  color='teal'
                  content='Event Location (Business Place)'
                />
                <Field
                  name='venue'
                  type='text'
                  component={PlaceInput}
                  options={{
                    componentRestrictions: { country: 'id' },
                    location: new google.maps.LatLng(cityLatLng),
                    radius: 1000,
                    types: ['establishment'],
                  }}
                  placeholder='Event venue (Business Place)'
                  onSelect={handleVenueSelect}
                />
                <Header sub color='teal' content='Event Date' />
                <Field
                  name='date'
                  type='text'
                  component={DateInput}
                  dateFormat='dd LLL yyyy H:mm a'
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  showTimeSelect
                  placeholder='Event date'
                />
                <Button
                  loading={loading}
                  disabled={invalid || submitting || pristine}
                  positive
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  disabled={submitting || loading}
                  onClick={history.goBack}
                  type='button'
                >
                  Cancel
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      )}
      {isDesktopOrLaptop && (
        <Grid>
          <Grid.Column width={12}>
            <Segment>
              <Form
                onSubmit={handleSubmit(handleFormSubmit)}
                autoComplete='off'
              >
                <Header sub color='teal' content='Event Name' />
                <Field
                  name='title'
                  type='text'
                  component={TextInput}
                  placeholder='Give your event a name'
                />
                <Header sub color='teal' content='Event Category' />
                <Field
                  name='category'
                  type='text'
                  options={category}
                  component={SelectInput}
                  onChange={handleSelectCategory}
                  placeholder='What is your event about'
                />
                <Grid style={{ marginBottom: '5px' }}>
                  <Grid.Row />
                  <Grid.Column width={4}>
                    <Header color='teal' sub content='Step 1 - Add Photo' />
                    <DropZoneInput setFiles={setFiles} />
                  </Grid.Column>
                  <Grid.Column width={1} />
                  <Grid.Column width={4}>
                    <Header sub color='teal' content='Step 2 - Resize image' />

                    {/* {files && files.length > 0 && (
                      <div style={{ fontSize: '11px', color: 'red' }}>
                        *if image size larger than 1mb, you may have lagging
                        when cropping
                      </div>
                    )} */}

                    {files.length > 0 && (
                      <CropperInput
                        aspectRatio={290 / 200}
                        imagePreview={files[0].preview}
                        filesType={files[0].type}
                        setImage={setImage}
                        setCropResult={setCropResult}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column width={1} />
                  <Grid.Column width={4}>
                    <Header sub color='teal' content='Step 3 - Preview' />
                    {files.length > 0 && (
                      <Fragment>
                        <div
                          className='img-preview'
                          style={{
                            minHeight: '200px',
                            minWidth: '235px',
                            overflow: 'hidden',
                          }}
                        />

                        <Button
                          onClick={handleCancelCrop}
                          style={{ width: '235px' }}
                          icon='close'
                        />
                      </Fragment>
                    )}
                  </Grid.Column>
                </Grid>

                {selectedCategory && selectedCategory === 'commercial' && (
                  <Fragment>
                    <Header sub color='teal' content='Price' />
                    <Field
                      name='price'
                      type='text'
                      component={TextInput}
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        marginBottom: '8px',
                      }}
                      {...currencyMask}
                      placeholder='Price per item'
                    />
                    <Header sub color='teal' content='Payment Method' />
                    <Field
                      name='paymentMethod'
                      type='text'
                      options={paymentAvaliable}
                      multiple={true}
                      component={SelectInput}
                      style={{
                        width: '100%',
                        marginTop: '8px',
                        marginBottom: '17px',
                      }}
                      placeholder='Select avaliable payment method'
                    />
                    <Header sub color='teal' content='Minimal People' />
                    <Field
                      name='minPeople'
                      component={TextInput}
                      type='number'
                      placeholder='Minimal People'
                    />
                    <Header
                      sub
                      color='teal'
                      content='Automatic lockout when minimal people fulfilled'
                    />
                    <Form.Group style={{ marginTop: '11px' }}>
                      <Field
                        name='lockOutMinPeople'
                        component={RadioInput}
                        type='radio'
                        value='no'
                        label='No'
                      />
                      <Field
                        name='lockOutMinPeople'
                        component={RadioInput}
                        type='radio'
                        value='yes'
                        label='Yes'
                      />
                    </Form.Group>
                    <Form.Group inline style={{ marginTop: '19px' }}>
                      <Header sub color='teal' content='Schedule ? ' />
                      <div style={{ marginLeft: '1.3em' }}>
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='noSchedule'
                          label='No'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='sn-rb-jm'
                          label='SN-RB-JM'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='sl-km-sb'
                          label='SL-KM-SB'
                        />
                        <Field
                          name='schedule'
                          component={RadioInput}
                          type='radio'
                          value='everyWeek'
                          label='Every week'
                        />
                      </div>
                    </Form.Group>
                    <Header
                      sub
                      color='teal'
                      content='Automatic LockOut or Cancelled event'
                      style={{ marginBottom: '1em' }}
                    />
                    <Form.Group>
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='oneHour'
                        label='1 hour'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twoHours'
                        label='2 hours'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='fiveHours'
                        label='5 hours'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twelveHours'
                        label='12 hours'
                      />
                      <Field
                        name='lockOutOrCancelledTime'
                        component={RadioInput}
                        type='radio'
                        value='twentyFourHours'
                        label='24 hours'
                      />
                    </Form.Group>
                  </Fragment>
                )}
                <Header sub color='teal' content='Event Description' />
                <Field
                  name='description'
                  type='text'
                  rows={3}
                  component={TextArea}
                  placeholder='Tell us about your event'
                />
                <Header sub color='teal' content='Event Location City' />
                <Field
                  name='city'
                  type='text'
                  component={PlaceInput}
                  options={{
                    types: ['(cities)'],
                    componentRestrictions: { country: 'id' },
                  }}
                  placeholder='Event city'
                  onSelect={handleCitySelect}
                />
                <Header
                  sub
                  color='teal'
                  content='Event Location (Business Place)'
                />
                <Field
                  name='venue'
                  type='text'
                  component={PlaceInput}
                  options={{
                    componentRestrictions: { country: 'id' },
                    location: new google.maps.LatLng(cityLatLng),
                    radius: 1000,
                    types: ['establishment'],
                  }}
                  placeholder='Event venue (Business Place)'
                  onSelect={handleVenueSelect}
                />
                <Header sub color='teal' content='Event Date' />
                <Field
                  name='date'
                  type='text'
                  component={DateInput}
                  dateFormat='dd LLL yyyy H:mm a'
                  timeFormat='HH:mm'
                  timeIntervals={15}
                  showTimeSelect
                  placeholder='Event date'
                />
                <Button
                  loading={loading}
                  disabled={invalid || submitting || pristine}
                  positive
                  type='submit'
                >
                  Submit
                </Button>
                <Button
                  disabled={submitting || loading}
                  onClick={history.goBack}
                  type='button'
                >
                  Cancel
                </Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default reduxForm({ form: 'eventFormCre', validate })(EventForm);
