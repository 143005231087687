import React, { useCallback } from "react";
import { Segment, Form, Header, Divider, Button } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import DateInput from "../../../app/common/form/DateInput";
import PlaceInput from "../../../app/common/form/PlaceInput";
import TextInput from "../../../app/common/form/TextInput";
import RadioInput from "../../../app/common/form/RadioInput";
import { addYears } from "date-fns";
import { updateProfile } from "../../user/userActions";
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan
} from "revalidate";

const validate = combineValidators({
  displayName: composeValidators(
    isRequired({ message: "Display name is required" }),
    hasLengthGreaterThan(4)({
      message: "display name needs to be at least 5 characters"
    })
  )()
});


const BasicPage = ({ pristine, submitting, handleSubmit, invalid }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.async.loading, []);
  const handleUpdateProfile = useCallback(
    user => {
      return dispatch(updateProfile(user));
    },
    [dispatch]
  );

  return (
    <Segment>
      <Header dividing size="large" content="Basics" />
      <Form onSubmit={handleSubmit(handleUpdateProfile)} autoComplete="off">
        <Field
          width={8}
          name="displayName"
          type="text"
          component={TextInput}
          placeholder="Known As"
        />
        <Form.Group inline>
          <label>Gender: </label>
          <Field
            name="gender"
            type="radio"
            value="male"
            label="Male"
            component={RadioInput}
          />
          <Field
            name="gender"
            type="radio"
            value="female"
            label="Female"
            component={RadioInput}
          />
        </Form.Group>
        <Field
          width={8}
          name="dateOfBirth"
          component={DateInput}
          placeholder="Date of Birth"
          dateFormat="dd LLL yyyy"
          showYearDropdown={true}
          showMonthDropdown={true}
          dropdownMode="select"
          maxDate={addYears(new Date(), -18)}
        />
        <Field
          name="city"
          placeholder="Home Town"
          options={{ types: ["(cities)"] }}
          label="Female"
          component={PlaceInput}
          width={8}
        />
        <Divider />
        <Button
          loading={loading}
          disabled={loading || pristine || submitting || invalid}
          size="large"
          positive
          content="Update Profile"
        />
      </Form>
    </Segment>
  );
};

export default reduxForm({
  form: "userProfile",
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(BasicPage);
