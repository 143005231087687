import { toastr } from 'react-redux-toastr';
import cuid from 'cuid';
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
} from '../async/asyncActions';
import { GET_USER_EVENTS } from './userConstants';
import firebase from '../../app/config/firebase';
import { ASYNC_ACTION_START } from '../async/asyncConstants';

export const updateProfile = (userChanges) => async (dispatch, getState) => {
  const firestore = firebase.firestore();
  const user = firebase.auth().currentUser;
  const today = new Date();
  let userDocRef = firestore.collection('users').doc(user.uid);
  let eventAttendeeRef = firestore.collection('event_attendee');

  try {
    dispatch(asyncActionStart());

    let batch = firestore.batch();
    batch.update(userDocRef, {
      ...userChanges,
    });

    await user.updateProfile({
      displayName: userChanges.displayName,
    });

    let eventQuery = await eventAttendeeRef
      .where('userUid', '==', user.uid)
      .where('eventDate', '>=', today);

    let eventQuerySnap = await eventQuery.get();

    for (let i = 0; i < eventQuerySnap.docs.length; i++) {
      let eventDocRef = await firestore
        .collection('events')
        .doc(eventQuerySnap.docs[i].data().eventId);
      let event = await eventDocRef.get();

      if (event.data().hostUid === user.uid) {
        batch.update(eventDocRef, {
          hostedBy: userChanges.displayName,
          [`attendees.${user.uid}.displayName`]: userChanges.displayName,
        });
      } else {
        batch.update(eventDocRef, {
          [`attendees.${user.uid}.displayName`]: userChanges.displayName,
        });
      }
    }

    await batch.commit();

    dispatch(asyncActionFinish());
    toastr.success('Success', 'Your profile has been updated');
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error('Problem updating profile');
  }
};

export const uploadProfileImage = ({ firebase, firestore }, file) => async (
  dispatch
) => {
  const imageName = cuid();
  const user = firebase.auth().currentUser;
  const path = `${user.uid}/user_images`;
  const options = {
    name: imageName,
  };
  try {
    dispatch(asyncActionStart());
    // upload the file to firebase storage
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    // get url of image
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
    // get userdoc

    // let userDoc = await firestore.get(`users/${user.uid}`);
    // check if user has photo, if not update profile

    // if (!userDoc.data().photoURL) {
    //   await firebase.updateProfile({
    //     photoURL: downloadURL
    //   });
    //   await user.updateProfile({
    //     photoURL: downloadURL
    //   });
    // }

    // add the image to firestore
    await firestore.add(
      {
        collection: 'users',
        doc: user.uid,
        subcollections: [{ collection: 'photos' }],
      },
      {
        name: imageName,
        url: downloadURL,
      }
    );
    toastr.success('Success', 'Photo has been uploaded');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem uploading the photo');
  }
};

export const deletePhoto = ({ firebase, firestore }, photo) => async (
  dispatch
) => {
  const user = firebase.auth().currentUser;
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: photo.name });
    await firebase.deleteFile(`${user.uid}/user_images/${photo.name}`);
    await firestore.delete({
      collection: 'users',
      doc: user.uid,
      subcollections: [{ collection: 'photos', doc: photo.id }],
    });
    toastr.success('Success', 'Photo has been deleted');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem deleting the photo');
    throw new Error('Problem deleting the photo');
  }
};

export const setMainPhoto = ({ firebase, firestore }, photo) => async (
  dispatch
) => {
  const user = firebase.auth().currentUser;
  const today = new Date();
  let userDocRef = firestore.collection('users').doc(user.uid);
  let eventAttendeeRef = firestore.collection('event_attendee');
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: photo.id });
    let batch = firestore.batch();

    batch.update(userDocRef, {
      photoURL: photo.url,
    });

    let eventQuery = await eventAttendeeRef
      .where('userUid', '==', user.uid)
      .where('eventDate', '>=', today);

    let eventQuerySnap = await eventQuery.get();

    for (let i = 0; i < eventQuerySnap.docs.length; i++) {
      let eventDocRef = await firestore
        .collection('events')
        .doc(eventQuerySnap.docs[i].data().eventId);
      let event = await eventDocRef.get();
      if (event.data().hostUid === user.uid) {
        batch.update(eventDocRef, {
          hostPhotoURL: photo.url,
          [`attendees.${user.uid}.photoURL`]: photo.url,
        });
      } else {
        batch.update(eventDocRef, {
          [`attendees.${user.uid}.photoURL`]: photo.url,
        });
      }
    }

    await batch.commit();
    toastr.success('Success', 'Your profile photo has been changed');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem setting main photo');
    throw new Error('Problem setting main photo');
  }
};

export const goingToEvent = (event, userOrder) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();
  const user = firebase.auth().currentUser;
  const profile = getState().firebase.profile;
  let attendee = {};

  if (
    event.category !== 'hangOut' &&
    userOrder &&
    userOrder.paymentMethod === 'bankTransfer'
  ) {
    attendee = {
      going: false,
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Waiting Transfer',
    };
  } else if (
    event.category !== 'hangOut' &&
    userOrder &&
    userOrder.paymentMethod === 'creditCard'
  ) {
    attendee = {
      going: true,
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Waiting Payment',
    };
  } else if (
    event.category !== 'hangOut' &&
    userOrder &&
    userOrder.paymentMethod === 'cod'
  ) {
    attendee = {
      going: true,
      joinDate: new Date(),
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Joined Event',
    };
  } else {
    attendee = {
      going: true,
      joinDate: new Date(),
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      status: 'Joined Event',
    };
  }
  try {
    let blackListId = [];
    let eventDocRef = firestore.collection('events').doc(event.id);
    let eventAttendeeDocRef = firestore
      .collection('event_attendee')
      .doc(`${event.id}_${user.uid}`);

    await firestore.runTransaction(async (transaction) => {
      const eventDetail = await transaction.get(eventDocRef);

      if (
        eventDetail.data().blackListId &&
        eventDetail.data().blackListId.length > 0
      ) {
        blackListId.push(eventDetail.data().blackListId);

        const userIdBlackList = blackListId[0].find((b) => b === user.uid);

        if (userIdBlackList) {
          let message = {};
          message.blackList = 'Black List';
          throw message;
        }
      }
      if (
        attendee.paymentMethod === 'creditCard' ||
        attendee.paymentMethod === 'bankTransfer'
      ) {
        await transaction.update(eventDocRef, {
          [`attendees.${user.uid}`]: attendee,
        });
      } else if (
        attendee.paymentMethod === 'cod' ||
        event.category === 'hangOut'
      ) {
        await transaction.update(eventDocRef, {
          [`attendees.${user.uid}`]: attendee,
        });
        await transaction.set(eventAttendeeDocRef, {
          eventId: event.id,
          userUid: user.uid,
          eventDate: event.date,
          host: false,
        });
      }
    });
    dispatch(asyncActionFinish());
    if (
      attendee.paymentMethod === 'cod' ||
      attendee.paymentMethod === 'hangOut'
    ) {
      toastr.success('Success', 'You have signed up to the event');
    } else if (attendee.paymentMethod === 'creditCard') {
      toastr.info(
        'Info',
        'Please submit your credit card payment to make sure you joined this event'
      );
    } else if (attendee.paymentMethod === 'bankTransfer') {
      toastr.info(
        'Info',
        'Please upload your payment transfer to make sure you joined this event'
      );
    }
  } catch (error) {
    console.log(error);
    if (error.blackList === 'Black List') {
      toastr.info('Info', 'You can not join this event because some reason');
    } else {
      toastr.error('Error', 'Problem signing up to the event');
    }
    dispatch(asyncActionFinish());
  }
};

export const goingToEventOrder = (event, userOrder) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();
  const user = firebase.auth().currentUser;
  const profile = getState().firebase.profile;
  let attendee = {};

  if (userOrder.paymentMethod === 'bankTransfer') {
    attendee = {
      going: false,
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Waiting Transfer',
    };
  } else if (userOrder.paymentMethod === 'creditCard') {
    attendee = {
      going: false,
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Waiting Payment',
    };
  } else {
    attendee = {
      going: true,
      joinDate: new Date(),
      photoURL: profile.photoURL || '/assets/user.png',
      displayName: profile.displayName,
      email: user.email,
      host: false,
      paymentMethod: userOrder.paymentMethod,
      orderQuantity: userOrder.quantity,
      orderPrice: userOrder.orderPrice,
      orderDescription: userOrder.orderDescription || '',
      status: 'Joined Event',
    };
  }
  try {
    let eventDocRef = firestore.collection('events').doc(event.id);
    let eventAttendeeDocRef = firestore
      .collection('event_attendee')
      .doc(`${event.id}_${user.uid}`);
    await firestore.runTransaction(async (transaction) => {
      await transaction.get(eventDocRef);
      await transaction.update(eventDocRef, {
        [`attendees.${user.uid}`]: attendee,
      });
      if (attendee.paymentMethod === 'cod') {
        await transaction.set(eventAttendeeDocRef, {
          eventId: event.id,
          userUid: user.uid,
          eventDate: event.date,
          host: false,
        });
      }
    });
    dispatch(asyncActionFinish());
    if (attendee.paymentMethod === 'cod') {
      toastr.success('Success', `You has joined to the event`);
    } else {
      toastr.info(
        'Info',
        'Please upload your payment transfer to make sure you joined this event'
      );
    }
  } catch (error) {
    console.log(error);
    dispatch(asyncActionFinish());
    toastr.error('Error', 'Problem signing up to the event');
  }
};

//TODO: this still contains the FieldValue.delete() bug so it will not remove the event from the reducer

export const cancelGoingToEvent = (
  { firebase, firestore },
  event,
  photoName,
  attendeeDisplayName,
  elementName
) => async (dispatch) => {
  const user = firebase.auth().currentUser;
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    if (photoName) {
      await firebase.deleteFile(
        `${event.id}/${user.uid}/payment_images/${photoName}`
      );
    }

    await firestore.delete(`event_attendee/${event.id}_${user.uid}`);
    await firestore.update(`events/${event.id}`, {
      [`attendees.${user.uid}`]: firestore.FieldValue.delete(),
    });
    if (attendeeDisplayName !== '') {
      toastr.info(
        'Info',
        `${attendeeDisplayName} payment's has rejected from this event`
      );
    } else {
      toastr.success('Success', `You has removed your self from this event`);
    }

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem cancelling your place');
  }
};

//TODO: check to see if there is a way to preserve the data in ordered reducer when using RRF
export const getUserEvents = (userUid, activeTab) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();
  const today = new Date(Date.now());
  let eventsRef = firestore.collection('event_attendee');
  let query;
  switch (activeTab) {
    case 1: // past events
      query = eventsRef
        .where('userUid', '==', userUid)
        .where('eventDate', '<=', today)
        .orderBy('eventDate', 'desc');
      break;
    case 2: // future events
      query = eventsRef
        .where('userUid', '==', userUid)
        .where('eventDate', '>=', today)
        .orderBy('eventDate');
      break;
    case 3: // hosted events
      query = eventsRef
        .where('userUid', '==', userUid)
        .where('host', '==', true)
        .orderBy('eventDate', 'desc');
      break;
    default:
      query = eventsRef
        .where('userUid', '==', userUid)
        .orderBy('eventDate', 'desc');
      break;
  }

  try {
    let querySnap = await query.get();
    let events = [];

    for (let i = 0; i < querySnap.docs.length; i++) {
      let evt = await firestore
        .collection('events')
        .doc(querySnap.docs[i].data().eventId)
        .get();
      events.push({ ...evt.data(), id: evt.id });
    }

    dispatch({ type: GET_USER_EVENTS, payload: { events } });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};

export const getUserEventsClientFilter = (userUid) => async (
  dispatch,
  getState
) => {
  dispatch(asyncActionStart());
  const firestore = firebase.firestore();

  let eventsRef = firestore.collection('event_attendee');
  const filterDate = new Date(new Date().setDate(new Date().getDate() - 1200));
  let query;

  query = eventsRef
    .where('userUid', '==', userUid)
    .where('eventDate', '>=', filterDate)
    .orderBy('eventDate', 'desc');

  try {
    let querySnap = await query.get();
    let events = [];

    for (let i = 0; i < querySnap.docs.length; i++) {
      let evt = await firestore
        .collection('events')
        .doc(querySnap.docs[i].data().eventId)
        .get();
      events.push({ ...evt.data(), id: evt.id });
    }

    dispatch({ type: GET_USER_EVENTS, payload: { events } });

    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
export const followerUser = (
  { firebase, firestore },
  userToFollow,
  elementName
) => async (dispatch, getState) => {
  const user = firebase.auth().currentUser;
  const following = {
    photoURL: userToFollow.photoURL || '/assets/user.png',
    city: userToFollow.city || 'Unknown city',
    email: userToFollow.email,
    displayName: userToFollow.displayName,
  };

  try {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    await firestore.set(
      {
        collection: 'users',
        doc: user.uid,
        subcollections: [{ collection: 'following', doc: userToFollow.id }],
      },
      following
    );
    toastr.success('Success', `You have following ${userToFollow.displayName}`);
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', `Problem following ${userToFollow.displayName}`);
  }
};

export const unfollowUser = (
  { firebase, firestore },
  userToUnfollow,
  elementName
) => async (dispatch, getState) => {
  const user = firebase.auth().currentUser;
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });
    await firestore.delete({
      collection: 'users',
      doc: user.uid,
      subcollections: [{ collection: 'following', doc: userToUnfollow.id }],
    });
    toastr.success(
      'Success',
      `You have unfollowing ${userToUnfollow.displayName}`
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    toastr.error('Error', `Problem unfollowing ${userToUnfollow.displayName}`);
    dispatch(asyncActionError());
  }
};
