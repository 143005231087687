import React, { useEffect, useMemo, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect, useFirebase } from "react-redux-firebase";
import { Grid } from "semantic-ui-react";
import UserDetailedDescription from "./UserDetailedDescription";
import UserDetailedEvents from "./UserDetailedEvents";
import UserDetailedHeader from "./UserDetailedHeader";
import UserDetailedPhotos from "./UserDetailedPhotos";
import UserDetailedSidebar from "./UserDetailedSidebar";
import { getUserEventsClientFilter } from "../userActions";
import { useMediaQuery } from "react-responsive";

const UserDetailedPage = ({ match: { params } }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const isCurrentUser = firebase.auth().currentUser.uid === params.id;
  const userProfileQuery = useMemo(
    () => ({
      collection: "users",
      doc: params.id,
      storeAs: "userProfile"
    }),
    [params.id]
  );

  const userPhotosQuery = useMemo(
    () => ({
      collection: "users",
      doc: params.id,
      subcollections: [{ collection: "photos" }],
      storeAs: "photos"
    }),
    [params.id]
  );
  useFirestoreConnect(userProfileQuery); // needs to be query object so can either get profile from store
  useFirestoreConnect(userPhotosQuery); // needs to be query object so can store as

  const profile = useSelector(
    state =>
      (state.firestore.ordered.userProfile &&
        state.firestore.ordered.userProfile[0]) ||
      {}
  );
  const photos = useSelector(
    state => state.firestore.ordered.photos && state.firestore.ordered.photos
  );
  const userEvents = useSelector(state => state.user.events) || [];
  const loading = useSelector(state => state.async.loading);

  useEffect(() => {
    dispatch(getUserEventsClientFilter(params.id));
  }, [dispatch, params.id]);

  const requesting = useSelector(state => state.firestore.status.requesting);

  const loadingReq = Object.values(requesting).some(a => a === true);

  const today = new Date(Date.now());

  const userHostEvent = userEvents.filter(event => event.hostUid === params.id);

  const userFutureEvent = userEvents.filter(
    event => event.date.toDate() >= today
  );

  const userPastEvent = userEvents.filter(
    event => event.date.toDate() <= today
  );

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid>
          <UserDetailedHeader profile={profile} loadingReq={loadingReq} />
          <UserDetailedSidebar
            isCurrentUser={isCurrentUser}
            profile={profile}
          />
          <UserDetailedDescription profile={profile} />
          {photos && photos.length > 0 && (
            <UserDetailedPhotos photos={photos} />
          )}
          <UserDetailedEvents
            allEvents={userEvents}
            userHostEvent={userHostEvent}
            userFutureEvent={userFutureEvent}
            userPastEvent={userPastEvent}
            loading={loading}
          />
        </Grid>
      )}
      {isDesktopOrLaptop && (
        <Grid>
          <UserDetailedHeader profile={profile} loadingReq={loadingReq} />
          <UserDetailedDescription profile={profile} />
          <UserDetailedSidebar
            isCurrentUser={isCurrentUser}
            profile={profile}
          />
          {photos && photos.length > 0 && (
            <UserDetailedPhotos photos={photos} />
          )}
          <UserDetailedEvents
            allEvents={userEvents}
            userHostEvent={userHostEvent}
            userFutureEvent={userFutureEvent}
            userPastEvent={userPastEvent}
            loading={loading}
          />
        </Grid>
      )}
    </Fragment>
  );
};

export default UserDetailedPage;
