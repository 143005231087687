import React, { useState } from 'react';
import { Segment, Grid, Icon, Button } from 'semantic-ui-react';
import { format } from 'date-fns';
import EventDetailedMap from './EventDetailedMap';
import NumberFormat from 'react-number-format';

const EventDetailedInfo = ({ event }) => {
  const [showMap, setShowMap] = useState(false);
  return (
    <Segment.Group>
      <Segment attached="top">
        <Grid>
          <Grid.Column width={1}>
            <Icon size="large" color="teal" name="info" />
          </Grid.Column>
          <Grid.Column width={15}>
            <p>{event.description}</p>
          </Grid.Column>
        </Grid>
      </Segment>
      {event.category === 'commercial' && (
        <>
          <Segment attached>
            <Grid verticalAlign="middle">
              <Grid.Column width={1}>
                <Icon
                  size="large"
                  color="teal"
                  name="money bill alternate outline"
                />
              </Grid.Column>
              <Grid.Column width={15}>
                <NumberFormat
                  value={event.price}
                  prefix="Rp "
                  displayType={'text'}
                  thousandSeparator={true}
                  thousandsGroupStyle="thousand"
                />{' '}
                <span style={{ marginLeft: '5px' }}>per item</span>
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment attached>
            <Grid verticalAlign="middle">
              <Grid.Column width={1}>
                <Icon size="large" color="teal" name="user outline" />
              </Grid.Column>
              <Grid.Column width={15}>
                <span>{event.minPeople}</span>
                <span style={{ marginLeft: '5px' }}>people (minimal)</span>
              </Grid.Column>
            </Grid>
          </Segment>
        </>
      )}

      <Segment attached>
        <Grid verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon name="calendar" size="large" color="teal" />
          </Grid.Column>
          <Grid.Column width={15}>
            {event.date && (
              <span>
                {format(event.date.toDate(), 'EEEE, dd LLLL')} at{' '}
                {format(event.date.toDate(), 'H:mm a')}
              </span>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment attached>
        <Grid verticalAlign="middle">
          <Grid.Column width={1}>
            <Icon name="marker" size="large" color="teal" />
          </Grid.Column>
          <Grid.Column width={11}>
            <span>{event.venue}</span>
          </Grid.Column>
          <Grid.Column width={4}>
            <Button
              onClick={() => setShowMap(!showMap)}
              color="teal"
              size="tiny"
              content={showMap ? 'Hide Map' : 'Show Map'}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      {showMap && (
        <EventDetailedMap
          lat={event.venueLatLng.lat}
          lng={event.venueLatLng.lng}
        />
      )}
    </Segment.Group>
  );
};

export default EventDetailedInfo;
