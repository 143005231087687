import React, { Component, Fragment } from "react";
import { Segment, Item, Label } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { format } from "date-fns";

class ComponentToPrint extends Component {
  render() {
    const { attendees, event } = this.props;
    const joinedAttendees = attendees.filter(
      attendee => attendee.status === "Joined Event" && attendee.host === false
    );
    return (
      <Segment attached>
        {event && <h2 style={{ textAlign: "center" }}>{event.title}</h2>}
        <h3 style={{ textAlign: "center", marginTop: "-10px" }}>
          <Fragment>
            {format(event.date && event.date.toDate(), "EEEE, do LLLL")}
            {format(event.date && event.date.toDate(), "H:mm a")}
          </Fragment>
        </h3>
        <h4
          style={{
            textAlign: "center",
            marginTop: "-10px"
          }}
        >
          {event && event.venue}
        </h4>

        <Item.Group
          divided
          style={{
            marginTop: "30px"
          }}
        >
          {joinedAttendees &&
            joinedAttendees.map(attendee => (
              <Item key={attendee.id} style={{ position: "relative" }}>
                <Item.Image size="tiny" src={attendee.photoURL} />
                <Item.Content verticalAlign="middle">
                  <Item.Header>{attendee.displayName}</Item.Header>
                  <Item.Meta>
                    <Label style={{ float: "right" }}>
                      {" "}
                      {format(
                        attendee.joinDate && attendee.joinDate.toDate(),
                        "EEEE, dd LLLL"
                      )}{" "}
                      at{" "}
                      {format(
                        attendee.joinDate && attendee.joinDate.toDate(),
                        "H:mm a"
                      )}
                    </Label>
                    <Label style={{ float: "right", clear: "right" }}>
                      {attendee.paymentMethod === "bankTransfer"
                        ? "Bank Transfer"
                        : attendee.paymentMethod === "cod"
                        ? "COD"
                        : attendee.paymentMethod === "creditCard"
                        ? "Credit Card"
                        : ""}
                    </Label>

                    <div style={{ color: "green" }}>
                      Quantity : {attendee.orderQuantity}
                    </div>
                    <div style={{ color: "green" }}>
                      Order Price :{" "}
                      <NumberFormat
                        value={attendee.orderPrice}
                        prefix="Rp "
                        displayType={"text"}
                        thousandSeparator={true}
                        thousandsGroupStyle="thousand"
                      />
                    </div>
                  </Item.Meta>
                  <Item.Description>
                    {attendee.orderDescription}
                  </Item.Description>
                </Item.Content>
              </Item>
            ))}
        </Item.Group>
      </Segment>
    );
  }
}
export default ComponentToPrint;
