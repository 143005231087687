import React, { useCallback, Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button, Item } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore, useFirebase } from "react-redux-firebase";
import { reset } from "redux-form";
import {
  confirmPayment,
  actionForAttendee,
  rejectPaymentAttendee
} from "../event/eventActions";
import { closeModal } from "../modals/modalActions";
import AttendeeDetailInfo from "../user/UserGoingToEvent/AttendeeDetailInfo";

const AttendeeDetailModal = attendee => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();
  const [actionSelected, setAction] = useState("");

  const auth = useSelector(state => state.firebase.auth, []);

  const event = useSelector(
    state =>
      (state.firestore.ordered.events && state.firestore.ordered.events[0]) ||
      {},
    []
  );
  const loading = useSelector(state => state.async.loading, []);
  const elementName = useSelector(state => state.async.elementName, []);
  const closeModalReset = useCallback(() => {
    dispatch(reset("userGoingToEventForm"));
    dispatch(closeModal());
  }, [dispatch]);

  const confirmAttendeePayment = useCallback(async () => {
    try {
      await dispatch(
        confirmPayment(
          { firestore },
          event,
          attendee.id,
          attendee.displayName,
          "confirmButton"
        )
      );
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, firestore, attendee, event]);
  const navigateToProfile = () => {
    attendee.history.push(`/profile/${attendee.id}`);
    dispatch(closeModal());
  };
  const rejectAttendeePayment = useCallback(async () => {
    try {
      await dispatch(
        rejectPaymentAttendee(
          { firebase, firestore },
          event.id,
          attendee.id,
          attendee.displayName,
          "rejectedButton",
          attendee.paymentImageName
        )
      );
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, firebase, firestore, event, attendee]);
  const eventActionForAttendee = useCallback(async () => {
    try {
      await dispatch(
        actionForAttendee(
          { firebase, firestore },
          event.id,
          attendee.id,
          attendee.displayName,
          actionSelected,
          attendee.paymentImageName
        )
      );
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, event, firebase, firestore, attendee, actionSelected]);

  return (
    <Modal size="mini" open={true} onClose={closeModalReset} closeIcon>
      <Modal.Header>
        <Item>
          <Item.Content verticalAlign="middle">
            <Item.Header
              style={{
                backgroundColor: "transparent",
                color: "darkBlue"
              }}
              as={Button}
              onClick={navigateToProfile}
            >
              {attendee.displayName}
            </Item.Header>
          </Item.Content>
        </Item>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <AttendeeDetailInfo
            initialValues={attendee}
            eventHostUid={event.hostUid}
            setAction={setAction}
            actionSelected={actionSelected}
            auth={auth}
          />
        </Modal.Description>
        {attendee.status === "Waiting Confirmation" && (
          <Fragment>
            <Button
              name="confirmButton"
              loading={loading && elementName === "confirmButton"}
              disabled={loading}
              style={{ marginTop: "10px" }}
              size="large"
              fluid
              color="teal"
              onClick={confirmAttendeePayment}
            >
              Confirm Payment
            </Button>
            <Button
              name="rejectedButton"
              style={{ marginTop: "9px" }}
              loading={loading && elementName === "rejectedButton"}
              disabled={loading}
              size="large"
              fluid
              color="red"
              onClick={rejectAttendeePayment}
            >
              Reject Payment
            </Button>
          </Fragment>
        )}
        {attendee.status === "Waiting Payment" && (
          <Fragment>
            <Button
              name="rejectedButton"
              style={{ marginTop: "14px" }}
              loading={loading && elementName === "rejectedButton"}
              disabled={loading}
              size="large"
              fluid
              color="red"
              onClick={rejectAttendeePayment}
            >
              Reject Attendee
            </Button>
          </Fragment>
        )}
        {attendee.status === "Joined Event" &&
          event.hostUid === auth.uid &&
          attendee.id !== event.hostUid && (
            <Button
              loading={loading}
              disabled={loading}
              size="large"
              fluid
              color="teal"
              style={{ marginTop: "9px" }}
              onClick={eventActionForAttendee}
            >
              Update Action
            </Button>
          )}
      </Modal.Content>
    </Modal>
  );
};

export default withRouter(AttendeeDetailModal);
