import React, { useRef, useCallback } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CropperInput = ({ imagePreview, setImage, aspectRatio, filesType }) => {
  const cropper = useRef(null);
  const cropImage = useCallback(() => {
    if (typeof cropper.current.getCroppedCanvas() === 'undefined') {
      return;
    }

    cropper.current.getCroppedCanvas().toBlob(
      blob => {
        setImage(blob);
      },
      filesType,
      '0.80'
    );
  }, [filesType, setImage]);

  return (
    <Cropper
      ref={cropper}
      src={imagePreview}
      style={{ height: 200, width: '100%' }}
      preview=".img-preview"
      aspectRatio={aspectRatio}
      viewMode={0}
      dragMode="move"
      guides={false}
      scalable={true}
      cropBoxMovable={true}
      cropBoxResizable={true}
      checkOrientation={true}
      crop={cropImage}
    />
  );
};

export default CropperInput;
