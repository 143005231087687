import React, { Fragment } from 'react';
import { Header, Card, Button } from 'semantic-ui-react';
import Img from 'react-image';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import LazyLoad from 'react-lazyload';

const UserPhotos = ({
  profile,
  deletePhoto,
  setMainPhoto,
  photos,
  loading,
  elementName
}) => {
  let filteredPhotos;
  if (photos) {
    filteredPhotos = photos.filter(photo => {
      return photo.url !== profile.photoURL;
    });
  }
  return (
    <Fragment>
      <Header
        sub
        color="teal"
        content="All Photos"
        style={{ marginBottom: '15px' }}
      />
      <Card.Group doubling itemsPerRow={4}>
        <LazyLoad height={140}>
          <Card>
            <Img
              src={profile.photoURL || '/assets/user.png'}
              loader={<LoadingComponent />}
              style={{ width: '100%' }}
            />

            <Button positive style={{ marginTop: '5px' }}>
              Main Photo
            </Button>
          </Card>
        </LazyLoad>
        {photos &&
          filteredPhotos.map((photo, index) => (
            <LazyLoad height={140} key={index}>
              <Card>
                <Img
                  src={photo.url}
                  loader={<LoadingComponent />}
                  unloader={<span>Image not found</span>}
                  style={{ width: '100%' }}
                />

                <div className="ui two buttons" style={{ marginTop: '5px' }}>
                  <Button
                    disabled={loading}
                    loading={loading && elementName === photo.id}
                    name={photo.id}
                    onClick={() => setMainPhoto(photo)}
                    basic
                    color="green"
                  >
                    Main
                  </Button>
                  <Button
                    disabled={loading}
                    loading={loading && elementName === photo.name}
                    name={photo.name}
                    onClick={() => deletePhoto(photo)}
                    basic
                    icon="trash"
                    color="red"
                  />
                </div>
              </Card>
            </LazyLoad>
          ))}
      </Card.Group>
    </Fragment>
  );
};

export default UserPhotos;
