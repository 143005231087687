import React from 'react';
import { useDispatch } from 'react-redux';
import { Segment, Item, Label, Button, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { openModal } from '../../modals/modalActions';
import PrintEventOrder from '../PrintEvent/PrintEventOrder';
import StripeCheckoutButton from '../../user/UserGoingToEvent/StripeButton';
import { useMediaQuery } from 'react-responsive';
import ReactLazy from 'react-lazyload';
import Img from 'react-image';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const EventDetailedSidebar = ({ attendees, auth, event }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const dispatch = useDispatch();
  return (
    <div>
      <Segment
        textAlign="center"
        style={{ border: 'none' }}
        attached="top"
        secondary
        inverted
        color="teal"
      >
        <PrintEventOrder attendees={attendees} event={event} auth={auth} />
        {event.category === 'commercial' &&
          attendees &&
          attendees.filter(a => a.host === false && a.status === 'Joined Event')
            .length}
        {event.category === 'hangOut' && attendees && attendees.length}
        {attendees &&
        attendees.filter(a => a.host === false && a.status === 'Joined Event')
          .length === 0
          ? ' People'
          : ' People going'}
      </Segment>
      {isTabletOrMobileDevice && (
        <Segment attached>
          <Card.Group itemsPerRow={2}>
            {attendees &&
              attendees.map(attendee => (
                <ReactLazy key={attendee.id} height={150}>
                  <Card style={{ position: 'relative' }}>
                    {attendee.host && (
                      <Label
                        style={{ position: 'absolute' }}
                        color="orange"
                        ribbon="left"
                      >
                        Host
                      </Label>
                    )}
                    {!attendee.host && attendee.id === auth.uid && (
                      <Label
                        style={{ position: 'absolute' }}
                        color="green"
                        ribbon="left"
                      >
                        Me
                      </Label>
                    )}
                    <Img
                      src={attendee.photoURL || '/assets/user.png'}
                      loader={<LoadingComponent />}
                      style={{ width: '100%' }}
                    />

                    <Card.Content
                      textAlign="center"
                      style={{ overflow: 'hidden' }}
                    >
                      {event.hostUid === auth.uid &&
                      event.category === 'commercial' ? (
                        <Item.Content verticalAlign="middle">
                          <Item.Header
                            style={{
                              backgroundColor: 'transparent',
                              color: 'darkBlue',
                              fontWeight: 'bold',
                              fontSize: '17px'
                            }}
                            as={Button}
                            onClick={() =>
                              dispatch(
                                openModal('AttendeeDetailInfo', attendee)
                              )
                            }
                          >
                            {attendee.displayName}
                          </Item.Header>
                          {attendee.status === 'Waiting Confirmation' && (
                            <div style={{ color: 'red' }}>
                              Waiting Confirmation
                            </div>
                          )}
                          {attendee.status === 'Waiting Payment' && (
                            <div style={{ color: 'red' }}>Waiting Payment</div>
                          )}
                        </Item.Content>
                      ) : (
                        <Item.Content verticalAlign="middle">
                          <Item.Header
                            as={Link}
                            to={`/profile/${attendee.id}`}
                            style={{
                              backgroundColor: 'transparent',
                              color: 'darkBlue',
                              fontSize: '17px',
                              fontWeight: 'bolder'
                            }}
                          >
                            {attendee.displayName}
                          </Item.Header>
                          {attendee.status === 'Waiting Confirmation' && (
                            <div style={{ color: 'red' }}>
                              Waiting Confirmation
                            </div>
                          )}
                          {attendee.status === 'Waiting Payment' && (
                            <div>
                              <span
                                style={{ color: 'red', marginRight: '10px' }}
                              >
                                Waiting Payment
                              </span>{' '}
                              {attendee.id === auth.uid && (
                                <StripeCheckoutButton
                                  attendee={attendee}
                                  event={event}
                                />
                              )}
                            </div>
                          )}
                        </Item.Content>
                      )}
                    </Card.Content>
                    <Card.Meta textAlign="center"></Card.Meta>
                  </Card>
                </ReactLazy>
              ))}
          </Card.Group>
        </Segment>
      )}
      <Segment attached>
        {isDesktopOrLaptop && (
          <Item.Group divided>
            {attendees &&
              attendees.map(attendee => (
                <Item key={attendee.id} style={{ position: 'relative' }}>
                  {attendee.host && (
                    <Label
                      style={{ position: 'absolute' }}
                      color="orange"
                      ribbon="right"
                    >
                      Host
                    </Label>
                  )}
                  {!attendee.host && attendee.id === auth.uid && (
                    <Label
                      style={{ position: 'absolute' }}
                      color="green"
                      ribbon="right"
                    >
                      Me
                    </Label>
                  )}
                  <Item.Image size="tiny" src={attendee.photoURL} />
                  {event.hostUid === auth.uid &&
                  event.category === 'commercial' ? (
                    <Item.Content verticalAlign="middle">
                      <Item.Header
                        style={{
                          backgroundColor: 'transparent',
                          color: 'darkBlue'
                        }}
                        as={Button}
                        onClick={() =>
                          dispatch(openModal('AttendeeDetailInfo', attendee))
                        }
                      >
                        {attendee.displayName}
                      </Item.Header>
                      {attendee.status === 'Waiting Confirmation' && (
                        <div style={{ color: 'red' }}>Waiting Confirmation</div>
                      )}
                      {attendee.status === 'Waiting Payment' && (
                        <div style={{ color: 'red' }}>Waiting Payment</div>
                      )}
                    </Item.Content>
                  ) : (
                    <Item.Content verticalAlign="middle">
                      <Item.Header
                        as={Link}
                        to={`/profile/${attendee.id}`}
                        style={{ color: 'darkBlue' }}
                      >
                        {attendee.displayName}
                      </Item.Header>
                      {attendee.status === 'Waiting Confirmation' && (
                        <div style={{ color: 'red' }}>Waiting Confirmation</div>
                      )}
                      {attendee.status === 'Waiting Payment' && (
                        <div>
                          <span style={{ color: 'red', marginRight: '10px' }}>
                            Waiting Payment
                          </span>{' '}
                          {attendee.id === auth.uid && (
                            <StripeCheckoutButton
                              attendee={attendee}
                              event={event}
                            />
                          )}
                        </div>
                      )}
                    </Item.Content>
                  )}
                </Item>
              ))}
          </Item.Group>
        )}
      </Segment>
    </div>
  );
};

export default EventDetailedSidebar;
