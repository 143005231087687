import React from 'react';
import { Menu, Dropdown, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

const SignedInMenu = ({ signOut }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 767px)'
  });
  const profile = useSelector(state => state.firebase.profile, []);
  const auth = useSelector(state => state.firebase.auth, []);
  return (
    <Menu.Item position="right">
      <Image
        avatar
        spaced="right"
        src={profile.photoURL || '/assets/user.png'}
      />
      <Dropdown pointing="top right" text={profile.displayName}>
        <Dropdown.Menu>
          <Dropdown.Item
            text="Create Event"
            icon="plus"
            as={Link}
            to="/createEvent"
          />
          {isTabletOrMobileDevice && (
            <>
              <Dropdown.Item
                as={Link}
                to="/events/activities"
                text="Events Activity"
                icon="clipboard"
              />
              <Dropdown.Item
                text="People"
                icon="users"
                as={Link}
                to="/people"
              />
            </>
          )}

          <Dropdown.Item
            as={Link}
            to={`/profile/${auth.uid}`}
            text="My Profile"
            icon="user"
          />
          <Dropdown.Item
            as={Link}
            to="/settings"
            text="Settings"
            icon="settings"
          />
          <Dropdown.Item onClick={signOut} text="Sign Out" icon="power" />
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

export default SignedInMenu;
