import React, { useMemo, Fragment } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Grid } from "semantic-ui-react";
import { Switch, Redirect, Route } from "react-router-dom";
import EventSettingsForm from "./EventSettingsForm";
import EventSettingsPhotos from "./EventSettingsPhotos";
import EventSettingsNav from "./EventSettingsNav";
import { useMediaQuery } from "react-responsive";

const EventSettingsDasboard = ({ match: { params } }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  const eventQuery = useMemo(
    () => ({
      collection: "events",
      doc: params.id,
      storeAs: "event"
    }),
    [params.id]
  );
  useFirestoreConnect(eventQuery);
  const eventPhotosQuery = useMemo(
    () => ({
      collection: "events",
      doc: params.id,
      subcollections: [{ collection: "eventPhotos" }],
      storeAs: "photos"
    }),
    [params.id]
  );
  useFirestoreConnect(eventPhotosQuery);

  const event = useSelector(
    state =>
      (state.firestore.ordered.event && state.firestore.ordered.event[0]) || {}
  );

  const photos = useSelector(
    state => state.firestore.ordered.photos && state.firestore.ordered.photos
  );

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid>
          <Grid.Column width={16}>
            <EventSettingsNav params={params} />
          </Grid.Column>
          <Grid.Column width={16}>
            <Switch>
              <Redirect
                exact
                from="/eventsettings/:id"
                to="/eventsettings/:id/form"
              />
              <Route
                path="/eventsettings/:id/form"
                render={() => <EventSettingsForm initialValues={event} />}
              />
              <Route
                path="/eventsettings/:id/photos"
                render={() => (
                  <EventSettingsPhotos photos={photos} event={event} />
                )}
              />
            </Switch>
          </Grid.Column>
        </Grid>
      )}
      {isDesktopOrLaptop && (
        <Grid>
          <Grid.Column width={12}>
            <Switch>
              <Redirect
                exact
                from="/eventsettings/:id"
                to="/eventsettings/:id/form"
              />
              <Route
                path="/eventsettings/:id/form"
                render={() => <EventSettingsForm initialValues={event} />}
              />
              <Route
                path="/eventsettings/:id/photos"
                render={() => (
                  <EventSettingsPhotos photos={photos} event={event} />
                )}
              />
            </Switch>
          </Grid.Column>
          <Grid.Column width={4}>
            <EventSettingsNav params={params} />
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default EventSettingsDasboard;
