import React, {
  useState,
  useEffect,
  Fragment,
  useCallback,
  useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useFirestoreConnect,
  useFirestore,
  useFirebase
} from 'react-redux-firebase';
import { Segment, Header, Divider, Grid, Button } from 'semantic-ui-react';
import DropzoneInput from './DropZoneInput';
import CropperInput from './CropperInput';
import {
  uploadProfileImage,
  deletePhoto,
  setMainPhoto
} from '../../userActions';
import UserPhotos from './UserPhotos';
import { useMediaQuery } from 'react-responsive';

const PhotosPage = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const auth = useSelector(state => state.firebase.auth, []);
  const elementName = useSelector(state => state.async.elementName, []);

  const userPhotosQuery = useMemo(
    () => ({
      collection: 'users',
      doc: auth.uid,
      subcollections: [{ collection: 'photos' }],
      storeAs: 'photos'
    }),
    [auth.uid]
  );

  // firestoreConnect equivalent
  useFirestoreConnect(userPhotosQuery);

  // mapstate equivalent

  const profile = useSelector(state => state.firebase.profile, []);
  const loading = useSelector(state => state.async.loading, []);
  const photos = useSelector(state => state.firestore.ordered.photos, []);

  // actions equivalent
  const [files, setFiles] = useState([]);
  const [cropResult, setCropResult] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
      URL.revokeObjectURL(cropResult);
    };
  }, [files, cropResult]);

  const handleUploadImage = useCallback(async () => {
    try {
      await dispatch(uploadProfileImage({ firebase, firestore }, image));
      handleCancelCrop();
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, firebase, firestore, image]);

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
    setCropResult('');
  };

  const handleDeletePhoto = useCallback(
    async photo => {
      try {
        await dispatch(deletePhoto({ firebase, firestore }, photo));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, firebase, firestore]
  );

  const handleSetMainPhoto = useCallback(
    async photo => {
      try {
        await dispatch(setMainPhoto({ firebase, firestore }, photo));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch, firebase, firestore]
  );

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Fragment>
          {isPortrait && (
            <Grid style={{ marginBottom: '5px' }}>
              <Grid.Row />
              <Grid.Column width={16} textAlign="center">
                <Header color="teal" sub content="Step 1 - Add Photo" />
                <DropzoneInput setFiles={setFiles} />
              </Grid.Column>

              <Grid.Column
                width={12}
                textAlign="center"
                className="center-image-preview"
              >
                <Header sub color="teal" content="Step 2 - Resize image" />
                {files && files.length > 0 && (
                  <div style={{ fontSize: '11px', color: 'red' }}>
                    *if image size larger than 1mb, you may have lagging when
                    cropping
                  </div>
                )}
                {files.length > 0 && (
                  <CropperInput
                    aspectRatio={1}
                    imagePreview={files[0].preview}
                    filesType={files[0].type}
                    setImage={setImage}
                    setCropResult={setCropResult}
                  />
                )}
              </Grid.Column>

              <Grid.Column width={16} textAlign="center">
                <Header
                  sub
                  color="teal"
                  content="Step 3 - Preview & Upload"
                  style={{ marginBottom: '7px' }}
                />
                {files.length > 0 && (
                  <Fragment>
                    <div
                      className="img-preview center-image-preview"
                      style={{
                        minHeight: '190px',
                        minWidth: '190px',
                        overflow: 'hidden'
                      }}
                    />

                    <Button.Group style={{ marginTop: '5px' }}>
                      <Button
                        loading={loading}
                        disabled={loading}
                        onClick={handleUploadImage}
                        style={{ width: '100px' }}
                        positive
                        icon="check"
                      />
                      <Button
                        disabled={loading}
                        onClick={handleCancelCrop}
                        style={{ width: '100px' }}
                        icon="close"
                      />
                    </Button.Group>
                  </Fragment>
                )}
              </Grid.Column>
            </Grid>
          )}

          {!isPortrait && (
            <div
              style={{
                fontSize: '13px',
                color: 'red',
                marginBottom: '10px',
                textAlign: 'center'
              }}
            >
              Please use portrait mode
            </div>
          )}

          <Divider />
          <UserPhotos
            photos={photos}
            profile={profile}
            deletePhoto={handleDeletePhoto}
            setMainPhoto={handleSetMainPhoto}
            loading={loading}
            elementName={elementName}
          />
        </Fragment>
      )}
      {isDesktopOrLaptop && (
        <Segment>
          <Header dividing size="large" content="Your Photos" />
          <Grid>
            <Grid.Row />
            <Grid.Column width={4}>
              <Header color="teal" sub content="Step 1 - Add Photo" />
              <DropzoneInput setFiles={setFiles} />
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color="teal" content="Step 2 - Resize image" />
              {files.length > 0 && (
                <CropperInput
                  aspectRatio={1}
                  imagePreview={files[0].preview}
                  filesType={files[0].type}
                  setImage={setImage}
                  setCropResult={setCropResult}
                />
              )}
            </Grid.Column>
            <Grid.Column width={1} />
            <Grid.Column width={4}>
              <Header sub color="teal" content="Step 3 - Preview & Upload" />
              {files.length > 0 && (
                <Fragment>
                  <div
                    className="img-preview"
                    style={{
                      minHeight: '200px',
                      minWidth: '200px',
                      overflow: 'hidden'
                    }}
                  />
                  <Button.Group>
                    <Button
                      disabled={loading}
                      loading={loading}
                      onClick={handleUploadImage}
                      style={{ width: '100px' }}
                      positive
                      icon="check"
                    />
                    <Button
                      disabled={loading}
                      onClick={handleCancelCrop}
                      style={{ width: '100px' }}
                      icon="close"
                    />
                  </Button.Group>
                </Fragment>
              )}
            </Grid.Column>
          </Grid>

          <Divider />
          <UserPhotos
            photos={photos}
            profile={profile}
            deletePhoto={handleDeletePhoto}
            setMainPhoto={handleSetMainPhoto}
            loading={loading}
            elementName={elementName}
          />
        </Segment>
      )}
    </Fragment>
  );
};
export default PhotosPage;
