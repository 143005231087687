import React, { Fragment } from "react";
import { Grid, Label, Tab, Dropdown } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import { format } from "date-fns";
import Img from "react-image";
import LazyLoad from "react-lazyload";
import LoadingComponent from "../../../app/layout/LoadingComponent";

const actions = [
  { key: "kick", text: "Kick from the event", value: "kick" },
  { key: "blackList", text: "Black list from the event", value: "blackList" }
];
const AttendeeDetailInfo = ({
  initialValues,
  eventHostUid,
  setAction,
  actionSelected,
  auth
}) => {
  let payMentMethodDisplayName = "";
  if (initialValues.paymentMethod === "bankTransfer") {
    payMentMethodDisplayName = "Bank Transfer";
  } else if (initialValues.paymentMethod === "creditCard") {
    payMentMethodDisplayName = "Credit Card";
  } else if (initialValues.paymentMethod === "cod") {
    payMentMethodDisplayName = "COD";
  }

  const handleSelectedAction = (e, data) => {
    setAction(data.value);
  };
  const panes = [
    {
      menuItem: "Order Description",
      render: () => (
        <Tab.Pane attached={false} style={{ minHeight: "300px" }}>
          {initialValues.joinDate && (
            <Fragment>
              {" "}
              <div style={{ marginBottom: "25px" }}>
                <span>Join Date: </span>
                <Label style={{ fontSize: "15px" }}>
                  {" "}
                  {format(
                    initialValues.joinDate && initialValues.joinDate.toDate(),
                    "EEEE, dd LLLL"
                  )}{" "}
                  at{" "}
                  {format(
                    initialValues.joinDate && initialValues.joinDate.toDate(),
                    "H:mm a"
                  )}{" "}
                </Label>
              </div>
            </Fragment>
          )}
          <div>
            <span>Payment Method: </span>{" "}
            <Label
              content={payMentMethodDisplayName}
              style={{ fontSize: "15px", marginLeft: "7px" }}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <span>Quantity: </span>{" "}
            <Label
              content={initialValues.orderQuantity}
              style={{ fontSize: "15px", marginLeft: "7px" }}
            />
          </div>
          <Grid
            textAlign="center"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            <Grid.Column>
              <span style={{ fontWeight: "bold", color: "green" }}>
                {" "}
                Order Price:{" "}
              </span>

              <NumberFormat
                style={{ fontWeight: "bold", color: "green" }}
                value={initialValues.orderPrice}
                prefix="Rp "
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
              />
            </Grid.Column>
          </Grid>
          <div style={{ marginTop: "7px" }}>
            <div>Description: </div>{" "}
            <Label
              content={
                initialValues.orderDescription !== ""
                  ? initialValues.orderDescription
                  : "No Description"
              }
              style={{ fontSize: "15px" }}
            />
          </div>

          {initialValues.status === "Joined Event" &&
            eventHostUid === auth.uid &&
            initialValues.id !== eventHostUid && (
              <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                <span>Actions: </span>{" "}
                <Dropdown
                  style={{ fontSize: "15px", marginLeft: "8px" }}
                  placeholder="Select a action"
                  options={actions}
                  onChange={handleSelectedAction}
                  value={actionSelected}
                />
                {actionSelected === "blackList" && (
                  <div style={{ marginTop: "10px", color: "red" }}>
                    If attendee push to black list, this attendee can not join
                    this event anymore and can not be modified
                  </div>
                )}
              </div>
            )}
        </Tab.Pane>
      )
    },
    {
      menuItem: "Payment Photo",
      render: () => (
        <Tab.Pane attached={false} style={{ minHeight: "300px" }}>
          {(initialValues.status === "Waiting Confirmation" ||
            initialValues.paymentMethod === "bankTransfer") && (
            <LazyLoad height={250}>
              <Img
                src={initialValues.paymentImageURL}
                loader={<LoadingComponent />}
                style={{ width: "100%", maxHeight: "260px", margin: "auto" }}
              />
            </LazyLoad>
          )}
        </Tab.Pane>
      )
    }
  ];

  return (
    <Fragment>
      {initialValues.status === "Waiting Confirmation" ||
      initialValues.paymentMethod === "bankTransfer" ? (
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      ) : (
        <Fragment>
          {initialValues.joinDate && (
            <Fragment>
              {" "}
              <div style={{ fontSize: "15px", marginBottom: "13px" }}>
                <span>Join Date: </span>
                <Label style={{ fontSize: "15px" }}>
                  {" "}
                  {format(
                    initialValues.joinDate && initialValues.joinDate.toDate(),
                    "EEEE, dd LLLL"
                  )}{" "}
                  at{" "}
                  {format(
                    initialValues.joinDate && initialValues.joinDate.toDate(),
                    "H:mm a"
                  )}{" "}
                </Label>
              </div>
            </Fragment>
          )}
          <div
            style={{
              marginBottom: "13px",
              marginTop: "20px"
            }}
          >
            <span>Payment Method: </span>{" "}
            <Label
              content={payMentMethodDisplayName}
              style={{
                fontSize: "15px",
                marginLeft: "7px"
              }}
            />
          </div>
          <div style={{ marginBottom: "10px", marginTop: "17px" }}>
            <span>Quantity: </span>{" "}
            <Label
              style={{ fontSize: "15px", marginLeft: "7px" }}
              content={initialValues.orderQuantity}
            />
          </div>
          <Grid
            textAlign="center"
            style={{ marginBottom: "10px", marginTop: "10px" }}
          >
            <Grid.Column>
              <span style={{ fontWeight: "bold", color: "green" }}>
                {" "}
                Order Price:{" "}
              </span>

              <NumberFormat
                style={{ fontWeight: "bold", color: "green" }}
                value={initialValues.orderPrice}
                prefix="Rp "
                displayType={"text"}
                thousandSeparator={true}
                thousandsGroupStyle="thousand"
              />
            </Grid.Column>
          </Grid>
          <div style={{ marginTop: "4px" }}>
            <div>Description: </div>{" "}
            <Label
              content={
                initialValues.orderDescription !== ""
                  ? initialValues.orderDescription
                  : "No Description"
              }
              style={{ fontSize: "15px" }}
            />{" "}
          </div>

          {initialValues.status === "Joined Event" &&
            eventHostUid === auth.uid &&
            initialValues.id !== eventHostUid && (
              <div style={{ marginTop: "20px", marginBottom: "22px" }}>
                <span>Actions: </span>{" "}
                <Dropdown
                  style={{
                    fontSize: "15px",
                    marginLeft: "8px"
                  }}
                  placeholder="Select a action"
                  options={actions}
                  onChange={handleSelectedAction}
                  value={actionSelected}
                />
                {actionSelected === "blackList" && (
                  <div style={{ marginTop: "10px", color: "red" }}>
                    If attendee push to black list, this attendee can not join
                    this event anymore and can not be modified
                  </div>
                )}
              </div>
            )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default AttendeeDetailInfo;
