import React from "react";
import { Grid, Header, Item, Segment, Image } from "semantic-ui-react";
import differenceInYears from "date-fns/differenceInYears";
import LazyLoad from "react-lazyload";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useMediaQuery } from "react-responsive";

const UserDetailedHeader = ({ profile, loadingReq }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  let age;
  if (profile.dateOfBirth) {
    age = differenceInYears(Date.now(), profile.dateOfBirth.toDate());
  }

  if (loadingReq) return <LoadingComponent />;
  return (
    <Grid.Column width={16}>
      <Segment>
        <Item.Group>
          <Item>
            <LazyLoad
              height={130}
              placeholder={<Image src="/assets/user.png" />}
            >
              <Item.Image
                avatar
                size="small"
                src={profile.photoURL || "/assets/user.png"}
              />
            </LazyLoad>
            {isTabletOrMobileDevice && (
              <Item.Content
                verticalAlign="bottom"
                className="center-image-preview"
              >
                <Header as="h1">{profile.displayName || ""}</Header>
                <br />
                <Header as="h3">{profile.occupation || ""}</Header>
                <br />
                <Header as="h4">{age ? `${age} years old` : ""}</Header>
                <br />
                <Header as="h4">{profile.city || ""}</Header>
              </Item.Content>
            )}
            {isDesktopOrLaptop && (
              <Item.Content verticalAlign="bottom">
                <Header as="h1">{profile.displayName || ""}</Header>
                <br />
                <Header as="h3">{profile.occupation || ""}</Header>
                <br />
                <Header as="h4">{age ? `${age} years old` : ""}</Header>
                <br />
                <Header as="h4">{profile.city || ""}</Header>
              </Item.Content>
            )}
          </Item>
        </Item.Group>
      </Segment>
    </Grid.Column>
  );
};

export default UserDetailedHeader;
