import { toastr } from 'react-redux-toastr';
import firebase from '../../app/config/firebase';
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError
} from '../async/asyncActions';
import { createNewEvent, objectToArray } from '../../app/common/util/helpers';
import { MORE_EVENTS, FETCH_EVENTS } from './eventConstants';
import cuid from 'cuid';
import { ASYNC_ACTION_START } from '../async/asyncConstants';
import { closeModal } from '../modals/modalActions';

export const createEvent = ({ firebase, firestore }, event, file, fileName) => {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    const photoURL = getState().firebase.profile.photoURL;
    const newEvent = createNewEvent(user, photoURL, event, firestore);

    const imageName = cuid();
    const options = {
      name: imageName
    };

    try {
      dispatch(asyncActionStart());
      let createdEvent = await firestore.add('events', newEvent);

      const path = `${createdEvent.id}/event_images`;
      let uploadedFile = await firebase.uploadFile(path, file, null, options);

      // get url image
      let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
      if (!createdEvent.eventPhotoURL)
        await firestore.update(`events/${createdEvent.id}`, {
          eventPhotoURL: downloadURL
        });
      await firestore.add(
        {
          collection: 'events',
          doc: createdEvent.id,
          subcollections: [{ collection: 'eventPhotos' }]
        },
        {
          name: imageName,
          url: downloadURL
        }
      );

      await firestore.set(`event_attendee/${createdEvent.id}_${user.uid}`, {
        eventId: createdEvent.id,
        userUid: user.uid,
        eventDate: event.date,
        host: true
      });
      toastr.success('Success!', 'Event has been created');
      dispatch(asyncActionFinish());
      return createdEvent;
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error('Oops', 'Something went wrong');
    }
  };
};

export const updateEvent = ({ firestore }, event) => {
  return async (dispatch, getState) => {
    try {
      dispatch(asyncActionStart());
      let eventDocRef = firestore.collection('events').doc(event.id);
      let dateEqual = getState().firestore.ordered.events[0].date.isEqual(
        event.date
      );
      if (!dateEqual) {
        let batch = firestore.batch();
        batch.update(eventDocRef, event);

        let eventAttendeeRef = firestore.collection('event_attendee');
        let eventAttendeeQuery = await eventAttendeeRef.where(
          'eventId',
          '==',
          event.id
        );
        let eventAttendeeQuerySnap = await eventAttendeeQuery.get();

        for (let i = 0; i < eventAttendeeQuerySnap.docs.length; i++) {
          let eventAttendeeDocRef = firestore
            .collection('event_attendee')
            .doc(eventAttendeeQuerySnap.docs[i].id);

          batch.update(eventAttendeeDocRef, {
            eventDate: event.date
          });
        }
        await batch.commit();
      } else {
        await eventDocRef.update(event);
      }
      toastr.success('Success!', 'Event has been updated');
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError());
      toastr.error('Error', 'Problem updating the event');
    }
  };
};

export const uploadEventImage = (
  { firebase, firestore },
  file,
  fileName,
  eventId
) => async dispatch => {
  const imageName = cuid();

  const path = `${eventId}/event_images`;
  const options = {
    name: imageName
  };
  try {
    dispatch(asyncActionStart());
    // upload the file to firebase storage
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    // get url of image
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

    // add the image to firestore
    await firestore.add(
      {
        collection: 'events',
        doc: eventId,
        subcollections: [{ collection: 'eventPhotos' }]
      },
      {
        name: imageName,
        url: downloadURL
      }
    );
    dispatch(asyncActionFinish());
    toastr.success('Success', 'Event photo has been added');
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem adding event photo');
  }
};

export const setMainEventPhoto = (
  { firebase, firestore },
  photo,
  eventId,
  photoId
) => async dispatch => {
  let eventDocRef = firestore.collection('events').doc(eventId);

  try {
    dispatch({ type: ASYNC_ACTION_START, payload: photoId });
    let batch = firestore.batch();

    batch.update(eventDocRef, {
      eventPhotoURL: photo.url
    });

    await batch.commit();
    toastr.success('Success', 'Main event has been changed');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error('Problem setting main photo');
  }
};

export const deletePhotoEvent = (
  { firebase, firestore },
  photo,
  eventId
) => async dispatch => {
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: photo.name });
    await firebase.deleteFile(`${eventId}/event_images/${photo.name}`);
    await firestore.delete({
      collection: 'events',
      doc: eventId,
      subcollections: [{ collection: 'eventPhotos', doc: photo.id }]
    });
    toastr.success('Success', 'Event photo has been deleted');
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    throw new Error('Problem deleting the photo');
  }
};

export const cancelToggle = (
  { firestore },
  cancelled,
  event
) => async dispatch => {
  const message =
    cancelled &&
    `Are you sure you want to cancel this event ??
    If event has cancelled can not be reactivated anymore`;

  try {
    toastr.confirm(message, {
      onOk: async () => {
        await firestore.update(`events/${event.id}`, {
          cancelled: cancelled
        });
        toastr.success(
          'Success',
          `You have cancelled this event => ${event.title} `
        );
      }
    });
  } catch (error) {
    console.log(error);
    toastr.error('Error', `Problem cancelling this event => ${event.title} `);
  }
};

export const uploadPaymentEvent = (
  { firebase, firestore },
  event,
  file
) => async dispatch => {
  const imageName = cuid();
  const user = firebase.auth().currentUser;
  const path = `${event.id}/${user.uid}/payment_images`;
  const options = {
    name: imageName
  };

  try {
    dispatch(asyncActionStart());
    let uploadedFile = await firebase.uploadFile(path, file, null, options);
    let downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();

    await firestore.update(`events/${event.id}`, {
      [`attendees.${user.uid}.paymentImageURL`]: downloadURL,
      [`attendees.${user.uid}.paymentImageName`]: imageName,
      [`attendees.${user.uid}.status`]: 'Waiting Confirmation',
      [`attendees.${user.uid}.going`]: true
    });
    toastr.success('Success', 'Payment image has been uploaded');
    dispatch(asyncActionFinish());
  } catch (error) {
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem uploading payment image');
  }
};

export const confirmPayment = (
  { firestore },
  event,
  attendeeId,
  attendeeDisplayName,
  elementName
) => async dispatch => {
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });

    let eventDocRef = firestore.collection('events').doc(event.id);
    let eventAttendeeDocRef = firestore
      .collection('event_attendee')
      .doc(`${event.id}_${attendeeId}`);
    await firestore.runTransaction(async transaction => {
      await transaction.get(eventDocRef);
      await transaction.update(eventDocRef, {
        [`attendees.${attendeeId}.status`]: 'Joined Event',
        [`attendees.${attendeeId}.joinDate`]: new Date()
      });

      await transaction.set(eventAttendeeDocRef, {
        eventId: event.id,
        userUid: attendeeId,
        eventDate: event.date,
        host: false
      });
    });
    toastr.success(
      'Success',
      `${attendeeDisplayName} payment's has been confirmed`
    );
    dispatch(asyncActionFinish());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem confirming payment');
  }
};

export const actionForAttendee = (
  { firebase, firestore },
  eventId,
  attendeeId,
  attendeeDisplayName,
  actionValue,
  photoName
) => async dispatch => {
  try {
    dispatch(asyncActionStart());
    if (!actionValue) {
      dispatch(asyncActionFinish());
      toastr.info('Info', 'Please select a action');
      return;
    } else if (actionValue === 'kick') {
      let eventDocRef = firestore.collection('events').doc(eventId);
      let eventAttendeeDocRef = firestore
        .collection('event_attendee')
        .doc(`${eventId}_${attendeeId}`);
      await firestore.runTransaction(async transaction => {
        if (photoName) {
          firebase.deleteFile(
            `${eventId}/${attendeeId}/payment_images/${photoName}`
          );
        }
        transaction.get(eventDocRef);
        transaction.update(eventDocRef, {
          [`attendees.${attendeeId}`]: firestore.FieldValue.delete()
        });
        transaction.delete(eventAttendeeDocRef);
      });
      toastr.success(
        'Success',
        `${attendeeDisplayName} has been kicked from this event`
      );
      dispatch(asyncActionFinish());
      dispatch(closeModal());
    } else if (actionValue === 'blackList') {
      let eventDocRef = firestore.collection('events').doc(eventId);
      let eventAttendeeDocRef = firestore
        .collection('event_attendee')
        .doc(`${eventId}_${attendeeId}`);
      await firestore.runTransaction(async transaction => {
        if (photoName) {
          firebase.deleteFile(
            `${eventId}/${attendeeId}/payment_images/${photoName}`
          );
        }
        const event = await transaction.get(eventDocRef);
        let listBlackListId = [];
        if (event.data().blackListId) {
          listBlackListId = listBlackListId.concat(event.data().blackListId);
        }

        listBlackListId.push(attendeeId);

        transaction.update(eventDocRef, {
          [`attendees.${attendeeId}`]: firestore.FieldValue.delete()
        });
        transaction.update(eventDocRef, {
          blackListId: listBlackListId
        });

        transaction.delete(eventAttendeeDocRef);
      });

      toastr.success(
        'Success',
        `${attendeeDisplayName} has been black list from this event`
      );

      dispatch(asyncActionFinish());
      dispatch(closeModal());
    }
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem with updating');
  }
};

export const rejectPaymentAttendee = (
  { firebase, firestore },
  eventId,
  attendeeId,
  attendeeDisplayName,
  elementName,
  photoName
) => async dispatch => {
  try {
    dispatch({ type: ASYNC_ACTION_START, payload: elementName });

    let eventDocRef = firestore.collection('events').doc(eventId);
    let eventAttendeeDocRef = firestore
      .collection('event_attendee')
      .doc(`${eventId}_${attendeeId}`);
    await firestore.runTransaction(async transaction => {
      if (photoName) {
        firebase.deleteFile(
          `${eventId}/${attendeeId}/payment_images/${photoName}`
        );
      }
      transaction.get(eventDocRef);
      transaction.update(eventDocRef, {
        [`attendees.${attendeeId}`]: firestore.FieldValue.delete()
      });
      transaction.delete(eventAttendeeDocRef);
    });
    toastr.success('Success', `${attendeeDisplayName} has been rejected`);
    dispatch(asyncActionFinish());
    dispatch(closeModal());
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
    toastr.error('Error', 'Problem rejecting attendee');
  }
};

export const getPagedEvents = ({ firestore }) => async (dispatch, getState) => {
  dispatch(asyncActionStart());
  const LIMIT = 2;
  let nextEventSnapshot = null;
  const {
    firestore: {
      data: { events: items }
    }
  } = getState();
  if (items && Object.keys(items).length >= LIMIT) {
    let itemsArray = objectToArray(items);
    nextEventSnapshot = await firestore
      .collection('events')
      .doc(itemsArray[itemsArray.length - 1].id)
      .get();
  }

  let querySnap = await firestore.get({
    collection: 'events',
    limit: LIMIT,
    // where: ["date", ">=", new Date()],
    orderBy: ['date', 'desc'],
    startAfter: nextEventSnapshot,
    storeAs: 'events'
  });

  if (querySnap.docs.length < LIMIT) {
    dispatch({ type: MORE_EVENTS });
  }
  dispatch(asyncActionFinish());
};

export const addEventComment = (
  { firebase },
  eventId,
  values,
  parentId
) => async (dispatch, getState) => {
  const profile = getState().firebase.profile;
  const user = firebase.auth().currentUser;
  let newComment = {
    parentId: parentId,
    displayName: profile.displayName,
    photoURL: profile.photoURL || '/assets/user.png',
    uid: user.uid,
    email: user.email,
    text: values.comment,
    date: Date.now()
  };
  try {
    await firebase.push(`event_chat/${eventId}`, newComment);
  } catch (error) {
    console.log(error);
    toastr.error('Oops', 'Problem adding comment');
  }
};

export const getEventsForDashboard = lastEvent => async (
  dispatch,
  getState
) => {
  // let today = new Date();
  const firestore = firebase.firestore();
  const eventsRef = firestore.collection('events');
  try {
    dispatch(asyncActionStart());
    let startAfter =
      lastEvent &&
      (await firestore
        .collection('events')
        .doc(lastEvent.id)
        .get());
    let query;

    lastEvent
      ? (query = eventsRef
          //  .where("date", ">=", today)
          .orderBy('date', 'desc')
          .startAfter(startAfter)
          .limit(2))
      : (query = eventsRef
          //   .where("date", ">=", today)
          .orderBy('date', 'desc')
          .limit(2));

    let querySnap = await query.get();

    if (querySnap.docs.length === 0) {
      dispatch(asyncActionFinish());
      return querySnap;
    }

    let events = [];

    for (let i = 0; i < querySnap.docs.length; i++) {
      let evt = { ...querySnap.docs[i].data(), id: querySnap.docs[i].id };
      events.push(evt);
    }
    dispatch({ type: FETCH_EVENTS, payload: { events } });
    dispatch(asyncActionFinish());
    return querySnap;
  } catch (error) {
    console.log(error);
    dispatch(asyncActionError());
  }
};
