import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { Grid, Menu, Header } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

const EventSettingsNav = ({ params: { id } }) => {
  return (
    <Grid.Column>
      <Menu vertical fluid>
        <Header
          icon="calendar"
          attached
          inverted
          color="grey"
          content="Settings Event"
        />
        {id && (
          <Fragment>
            <Menu.Item as={NavLink} to={`/eventsettings/${id}/form`}>
              Event Form
            </Menu.Item>
            <Menu.Item as={NavLink} to={`/eventsettings/${id}/photos`}>
              Event Photos
            </Menu.Item>
          </Fragment>
        )}
      </Menu>
    </Grid.Column>
  );
};

export default withRouter(EventSettingsNav);
