import React from "react";
import { Button, Icon } from "semantic-ui-react";

const SocialLogin = ({ socialLogin, submitting }) => {
  return (
    <div>
      <Button
        disabled={submitting}
        onClick={() => socialLogin("google")}
        type="button"
        fluid
        color="google plus"
        style={{ marginBottom: "10px" }}
      >
        <Icon name="google plus" />
        Login with Google
      </Button>
      
      {/* <Button
        disabled={submitting}
        onClick={() => socialLogin("facebook")}
        type="button"
        fluid
        color="facebook"
      >
        <Icon name="facebook" /> Login with Facebook
      </Button> */}
    </div>
  );
};

export default SocialLogin;
