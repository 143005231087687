import React from 'react';
import { Feed } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import Img from 'react-image';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const EventActivityItem = ({ activity }) => {
 
  const renderSummary = activity => {
    switch (activity.type) {
      case 'newEvent':
        return (
          <div style={{ marginTop: -10 }}>
            <span
              style={{ color: 'green', fontSize: 'bold', marginRight: '3px' }}
            >
              {' '}
              New Event!{' '}
            </span>
            <br />
            <Feed.User
              as={Link}
              to={{ pathname: '/profile/' + activity.hostUid }}
            >
              {activity.hostedBy}
            </Feed.User>{' '}
            is hosting{' '}
            <Link to={{ pathname: '/events/' + activity.eventId }}>
              {activity.title}
            </Link>
          </div>
        );
      case 'Cancelled':
        return (
          <div style={{ marginTop: -10 }}>
            <span
              style={{ color: 'red', fontSize: 'bold', marginRight: '3px' }}
            >
              {' '}
              Event Cancelled!{' '}
            </span>
            <br />
            <Feed.User
              as={Link}
              to={{ pathname: '/profile/' + activity.hostUid }}
            >
              {activity.hostedBy}
            </Feed.User>{' '}
            has cancelled{' '}
            <Link to={{ pathname: '/events/' + activity.eventId }}>
              {activity.title}
            </Link>
          </div>
        );
      case 'Modified':
        return (
          <div style={{ marginTop: -10 }}>
            <span
              style={{
                color: 'darkBlue',
                fontSize: 'bold',
                marginRight: '3px'
              }}
            >
              {' '}
              Event Modified!{' '}
            </span>
            <br />
            <Feed.User
              as={Link}
              to={{ pathname: '/profile/' + activity.hostUid }}
            >
              {activity.hostedBy}
            </Feed.User>{' '}
            has modified{' '}
            <Link to={{ pathname: '/events/' + activity.eventId }}>
              {activity.title}
            </Link>
          </div>
        );

      default:
        return;
    }
  };

  return (
    <Feed.Event>
      <Feed.Label>
        <Img
          src={activity.photoURL || '/assets/user.png'}
          loader={<LoadingComponent />}
          style={{ width: '100%' }}
        />
      </Feed.Label>
      <Feed.Content>
        <Feed.Summary>{renderSummary(activity)}</Feed.Summary>
        <Feed.Meta>
          <Feed.Date>
            {formatDistance(activity.timestamp.toDate(), Date.now())} ago
          </Feed.Date>
        </Feed.Meta>
      </Feed.Content>
    </Feed.Event>
  );
};

export default EventActivityItem;
