/*global google*/
import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { useFirestore } from 'react-redux-firebase';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  composeValidators,
  combineValidators,
  isRequired,
  hasLengthGreaterThan,
  isNumeric,
  createValidator
} from 'revalidate';
import { Form, Segment, Button, Grid, Header } from 'semantic-ui-react';
import { updateEvent, cancelToggle } from '../eventActions';
import TextInput from '../../../app/common/form/TextInput';
import TextArea from '../../../app/common/form/TextArea';
import SelectInput from '../../../app/common/form/SelectInput';
import DateInput from '../../../app/common/form/DateInput';
import PlaceInput from '../../../app/common/form/PlaceInput';
import { withRouter } from 'react-router';
import { createNumberMask } from 'redux-form-input-masks';
import { toastr } from 'react-redux-toastr';
import RadioInput from '../../../app/common/form/RadioInput';

const isLessThan = n =>
  createValidator(
    message => value => {
      if (value && Number(value) < n) {
        return message;
      }
    },
    field => `${field} must be greater than ${n}`
  );
const validate = combineValidators({
  title: isRequired({ message: 'The event title is required' }),
  category: isRequired({ message: 'Please provide a category' }),

  description: composeValidators(
    isRequired({ message: 'Please enter a description' }),
    hasLengthGreaterThan(4)({
      message: 'Description needs to be at least 5 characters'
    })
  )(),
  schedule: isRequired('schedule'),
  minPeople: composeValidators(
    isRequired({ message: 'Please enter a minimal people' }),
    isNumeric({ message: 'Must be a valid number' }),
    isLessThan(1)({
      message: 'Minimal people at least 1 people'
    })
  )(),
  lockOutMinPeople: isRequired('lockOutMinPeople'),
  lockOutOrCancelledTime: isRequired('lockOutOrCancelledTime'),
  city: isRequired('city'),
  venue: isRequired('venue'),
  date: isRequired('date'),
  price: isRequired('price'),
  paymentMethod: isRequired('paymentMethod')
});

const category = [
  { key: 'commercial', text: 'Commercial', value: 'commercial' },
  { key: 'hangOut', text: 'Hang Out', value: 'hangOut' }
];
const paymentAvaliable = [
  { key: 'cod', text: 'COD', value: 'cod' },
  { key: 'bankTransfer', text: 'Bank Transfer', value: 'bankTransfer' },
  { key: 'creditCard', text: 'Credit Card', value: 'creditCard' }
];

const currencyMask = createNumberMask({
  prefix: 'Rp ',
  suffix: ' per item',
  decimalPlaces: 0,
  locale: 'id-ID'
});

const EventForm = ({
  change,
  history,
  invalid,
  submitting,
  pristine,
  handleSubmit,
  initialValues
}) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const [cityLatLng, setCityLatLng] = useState({});
  const [venueLatLng, setVenueLatLng] = useState({});
  const loading = useSelector(state => state.async.loading, []);

  const handleCitySelect = selectedCity => {
    geocodeByAddress(selectedCity)
      .then(results => getLatLng(results[0]))
      .then(latlng => {
        setCityLatLng(latlng);
      })
      .then(() => {
        change('city', selectedCity);
      });
  };

  const handleVenueSelect = selectedVenue => {
    geocodeByAddress(selectedVenue)
      .then(results => getLatLng(results[0]))
      .then(latlng => {
        setVenueLatLng(latlng);
      })
      .then(() => {
        change('venue', selectedVenue);
      });
  };

  const handleFormSubmit = async values => {
    if (
      (values.category === 'commercial' &&
        (values.price === 0 || !values.price)) ||
      (values.category === 'commercial' && values.paymentMethod.length === 0)
    ) {
      toastr.info('Info', 'Please fill the form, all fields are mandatory');
      return;
    }
    if (values.schedule === 'noSchedule') {
      values.reschedule = false;
    } else if (values.schedule === 'sn-rb-jm') {
      values.reschedule = true;
    } else if (values.schedule === 'sl-km-sa') {
      values.reschedule = true;
    } else if (values.schedule === 'everyWeek') {
      values.reschedule = true;
    }
    values.venueLatLng = venueLatLng;
    values.cancelled = false;
    values.locked = false;
    values.finished = false;
    values.cloudNotes = '';
    if (initialValues.venueLatLng) {
      if (Object.keys(values.venueLatLng).length === 0) {
        values.venueLatLng = initialValues.venueLatLng;
      }
    }

    if (initialValues.id) {
      await dispatch(updateEvent({ firestore }, values));
      history.push(`/events/${initialValues.id}`);
    }
  };

  return (
    <Grid>
      <Grid.Column>
        <Segment>
          <Form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
            <Header sub color="teal" content="Event Name" />
            <Field
              name="title"
              type="text"
              component={TextInput}
              placeholder="Give your event a name"
            />
            <Header sub color="teal" content="Event Category" />
            <Field
              disabled
              name="category"
              type="text"
              options={category}
              component={SelectInput}
              placeholder="What is your event about"
            />
            {initialValues.category && initialValues.category === 'commercial' && (
              <Fragment>
                <Header sub color="teal" content="Event Price" />
                <Field
                  name="price"
                  type="text"
                  component={TextInput}
                  style={{
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '8px'
                  }}
                  {...currencyMask}
                  placeholder="Price per item"
                />
                <Header sub color="teal" content="Payment Method" />
                <Field
                  name="paymentMethod"
                  type="text"
                  options={paymentAvaliable}
                  multiple={true}
                  component={SelectInput}
                  defaultValue={[]}
                  style={{
                    width: '100%',
                    marginTop: '8px',
                    marginBottom: '17px'
                  }}
                  placeholder="Select avaliable payment method"
                />
                <Header sub color="teal" content="Minimal People" />
                <Field
                  name="minPeople"
                  component={TextInput}
                  type="number"
                  placeholder="Minimal People"
                />
                <Header
                  sub
                  color="teal"
                  content="Automatic lockout when minimal people fulfilled"
                />
                <Form.Group style={{ marginTop: '11px' }}>
                  <Field
                    name="lockOutMinPeople"
                    component={RadioInput}
                    type="radio"
                    value="no"
                    label="No"
                  />
                  <Field
                    name="lockOutMinPeople"
                    component={RadioInput}
                    type="radio"
                    value="yes"
                    label="Yes"
                  />
                </Form.Group>

                <Form.Group inline style={{ marginTop: '19px' }}>
                  <Header sub color="teal" content="Schedule ? " />
                  <div style={{ marginLeft: '1.3em' }}>
                    <Field
                      name="schedule"
                      component={RadioInput}
                      type="radio"
                      value="noSchedule"
                      label="No"
                    />
                    <Field
                      name="schedule"
                      component={RadioInput}
                      type="radio"
                      value="sn-rb-jm"
                      label="SN-RB-JM"
                    />
                    <Field
                      name="schedule"
                      component={RadioInput}
                      type="radio"
                      value="sl-km-sb"
                      label="SL-KM-SB"
                    />
                    <Field
                      name="schedule"
                      component={RadioInput}
                      type="radio"
                      value="everyWeek"
                      label="Every week"
                    />
                  </div>
                </Form.Group>
                <Header
                  sub
                  color="teal"
                  content="Automatic LockOut or Cancelled event"
                  style={{ marginBottom: '1em' }}
                />
                <Form.Group>
                  <Field
                    name="lockOutOrCancelledTime"
                    component={RadioInput}
                    type="radio"
                    value="oneHour"
                    label="1 hour"
                  />
                  <Field
                    name="lockOutOrCancelledTime"
                    component={RadioInput}
                    type="radio"
                    value="twoHours"
                    label="2 hours"
                  />
                  <Field
                    name="lockOutOrCancelledTime"
                    component={RadioInput}
                    type="radio"
                    value="fiveHours"
                    label="5 hours"
                  />
                  <Field
                    name="lockOutOrCancelledTime"
                    component={RadioInput}
                    type="radio"
                    value="twelveHours"
                    label="12 hours"
                  />
                  <div style={{ marginTop: '2em' }}></div>
                  <Field
                    name="lockOutOrCancelledTime"
                    component={RadioInput}
                    type="radio"
                    value="twentyFourHours"
                    label="24 hours"
                  />
                </Form.Group>
              </Fragment>
            )}
            <Header sub color="teal" content="Event Description" />
            <Field
              name="description"
              type="text"
              rows={3}
              component={TextArea}
              placeholder="Tell us about your event"
            />
            <Header sub color="teal" content="Event Location City" />
            <Field
              name="city"
              type="text"
              component={PlaceInput}
              options={{
                types: ['(cities)'],
                componentRestrictions: { country: 'id' }
              }}
              placeholder="Event city"
              onSelect={handleCitySelect}
            />
            <Header
              sub
              color="teal"
              content="Event Location (Business Place)"
            />
            <Field
              name="venue"
              type="text"
              component={PlaceInput}
              options={{
                componentRestrictions: { country: 'id' },
                location: new google.maps.LatLng(cityLatLng),
                radius: 1000,
                types: ['establishment']
              }}
              placeholder="Event venue (Business Place)"
              onSelect={handleVenueSelect}
            />
            <Header sub color="teal" content="Event Date" />
            <Field
              name="date"
              type="text"
              component={DateInput}
              dateFormat="dd LLL yyyy H:mm a"
              timeFormat="HH:mm"
              timeIntervals={15}
              showTimeSelect
              placeholder="Event date"
            />
            <Button
              loading={loading}
              disabled={
                invalid || submitting || pristine || initialValues.cancelled
              }
              positive
              type="submit"
            >
              Submit
            </Button>
            <Button onClick={history.goBack} type="button">
              Cancel
            </Button>

            <Button
              onClick={
                !initialValues.cancelled
                  ? () =>
                      dispatch(
                        cancelToggle(
                          { firestore },
                          !initialValues.cancelled,
                          initialValues
                        )
                      )
                  : () => {}
              }
              type="button"
              floated="right"
              color={'red'}
              disabled={loading}
              content={
                initialValues.cancelled
                  ? 'Cancelled event can not be modified anymore'
                  : 'Cancel event'
              }
            />
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(
  reduxForm({
    form: 'eventForm',
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false
  })(EventForm)
);
