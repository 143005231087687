import React, { Fragment } from 'react';
import { Segment, Item, Header, Button, Label } from 'semantic-ui-react';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { goingToEvent, cancelGoingToEvent } from '../../user/userActions';
import { openModal } from '../../modals/modalActions';
import { useMediaQuery } from 'react-responsive';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const EventDetailedHeader = ({
  attendee,
  attendeeStatus,
  event,
  isHost,
  isGoing,
  authenticated,
  photos
}) => {
  const firestore = useFirestore();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.async.loading, []);
  const elementName = useSelector(state => state.async.elementName, []);

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });

  const photosGalery = [];

  if (photos.length === 1) {
    photosGalery.push({
      original: event.eventPhotoURL,
      thumbnail: event.eventPhotoURL
    });
  } else {
    const notmainPhotos = photos.filter(
      photo => photo.url !== event.eventPhotoURL
    );

    photosGalery.push({
      original: event.eventPhotoURL,
      thumbnail: event.eventPhotoURL
    });

    notmainPhotos.map(photo => {
      return photosGalery.push({
        original: photo.url,
        thumbnail: photo.url
      });
    });
  }

  return (
    <Fragment>
      <Segment.Group>
        <Segment basic>
          <Item.Group>
            <Item>
              <Item.Content>
                <Header size="huge" content={event.title} />
                <p>
                  {format(event.date && event.date.toDate(), 'EEEE, dd LLLL')}
                </p>
                <p>
                  Hosted by{' '}
                  <strong>
                    <Link to={`/profile/${event.hostUid}`}>
                      {event.hostedBy}
                    </Link>
                  </strong>
                </p>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment
          basic
          attached="top"
          style={{ padding: '0', marginTop: '0px' }}
        >
          {photosGalery.length > 0 ? (
            <ImageGallery
              items={photosGalery}
              showPlayButton={false}
              lazyLoad={true}
              showIndex={true}
              showFullscreenButton={isTabletOrMobileDevice ? false : true}
            />
          ) : (
            <LoadingComponent />
          )}
        </Segment>

        <Segment attached="bottom" clearing>
          {event.cancelled && (
            <Label
              size="large"
              color="red"
              content="This event has been cancelled"
            />
          )}
          {!isHost && (
            <Fragment>
              {isGoing && !event.cancelled && !event.locked && (
                <Button
                  name="cancelButton"
                  loading={loading && elementName === 'cancelButton'}
                  disabled={loading}
                  onClick={() =>
                    dispatch(
                      cancelGoingToEvent(
                        { firebase, firestore },
                        event,
                        attendee.paymentImageName,
                        '',
                        'cancelButton'
                      )
                    )
                  }
                >
                  Cancel My Place
                </Button>
              )}
              {!isGoing &&
                !event.locked &&
                authenticated &&
                !event.cancelled &&
                event.category === 'hangOut' && (
                  <Button
                    loading={loading}
                    disabled={loading}
                    onClick={() => dispatch(goingToEvent(event))}
                    color="teal"
                  >
                    JOIN THIS EVENT
                  </Button>
                )}

              {!attendeeStatus &&
                !isGoing &&
                !event.locked &&
                authenticated &&
                !event.cancelled &&
                event.category === 'commercial' && (
                  <Button
                    onClick={() =>
                      dispatch(openModal('GoingToEventModal', event))
                    }
                    color="teal"
                  >
                    JOIN THIS EVENT
                  </Button>
                )}

              {!isGoing &&
                authenticated &&
                !event.locked &&
                !event.cancelled &&
                event.category === 'commercial' &&
                attendeeStatus === 'Waiting Transfer' && (
                  <Button
                    onClick={() =>
                      dispatch(openModal('PaymentUploadModal', event))
                    }
                    color="teal"
                  >
                    Upload Payment
                  </Button>
                )}

              {!authenticated && !event.cancelled && !event.locked && (
                <Button
                  onClick={() => dispatch(openModal('UnauthModal'))}
                  color="teal"
                >
                  JOIN THIS EVENT
                </Button>
              )}
            </Fragment>
          )}

          {isHost && !event.locked && (
            <Button
              as={Link}
              to={`/eventsettings/${event.id}`}
              color="orange"
              floated="right"
            >
              Manage Event
            </Button>
          )}
        </Segment>
      </Segment.Group>
    </Fragment>
  );
};

export default EventDetailedHeader;
