import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestore, useFirebase } from 'react-redux-firebase';
import { Segment, Header, Grid, Button } from 'semantic-ui-react';
import DropzoneInput from '../Settings/Photos/DropZoneInput';
import CropperInput from '../Settings/Photos/CropperInput';
import { uploadPaymentEvent } from '../../event/eventActions';
import { closeModal } from '../../modals/modalActions';

const UploadPaymentUser = ({ event }) => {
  const dispatch = useDispatch();
  const firestore = useFirestore();
  const firebase = useFirebase();

  const loading = useSelector(state => state.async.loading, []);

  // actions equivalent
  const [files, setFiles] = useState([]);
  const [cropResult, setCropResult] = useState('');
  const [image, setImage] = useState(null);

  useEffect(() => {
    return () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
      URL.revokeObjectURL(cropResult);
    };
  }, [files, cropResult]);

  const handleUploadImage = useCallback(async () => {
    try {
      await dispatch(uploadPaymentEvent({ firebase, firestore }, event, image));
      handleCancelCrop();
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, firebase, firestore, image, event]);

  const handleCancelCrop = () => {
    setFiles([]);
    setImage(null);
    setCropResult('');
  };

  return (
    <Segment>
      <Grid textAlign="center">
        <Grid.Column width={12}>
          <Header color="teal" sub content="Step 1 - Add Photo" />
          <DropzoneInput setFiles={setFiles} />
        </Grid.Column>

        <Grid.Column width={12}>
          <Header sub color="teal" content="Step 2 - Resize image" />
          {files.length > 0 && (
            <CropperInput
              aspectRatio={250 / 200}
              imagePreview={files[0].preview}
              setImage={setImage}
              setCropResult={setCropResult}
            />
          )}
        </Grid.Column>

        <Grid.Column width={12}>
          <Header sub color="teal" content="Step 3 - Preview & Upload" />
          {files.length > 0 && (
            <Fragment>
              <div
                className="img-preview"
                style={{
                  minHeight: '200px',
                  minWidth: '200px',
                  overflow: 'hidden'
                }}
              />
              <Button.Group>
                <Button
                  disabled={loading}
                  loading={loading}
                  onClick={handleUploadImage}
                  style={{ width: '120px', marginTop: '5px' }}
                  positive
                  icon="check"
                />
                <Button
                  disabled={loading}
                  onClick={handleCancelCrop}
                  style={{ width: '120px', marginTop: '5px' }}
                  icon="close"
                />
              </Button.Group>
            </Fragment>
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
export default UploadPaymentUser;
