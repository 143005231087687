import React from 'react';
import { Item, Segment, Icon, List, Button, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import LazyLoading from 'react-lazyload';
import EventListAttendee from './EventListAttendee';
import { objectToArray } from '../../../app/common/util/helpers';
import { useMediaQuery } from 'react-responsive';
import Img from 'react-image';
import LoadingComponent from '../../../app/layout/LoadingComponent';

const EventListItem = ({ event }) => {
  const joinAttendees = objectToArray(event.attendees).filter(attendee => {
    if (attendee.status === 'Joined Event' || attendee.host === true)
      return true;

    return false;
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  return (
    <Segment.Group>
      <Segment>
        <Item.Group>
          <Item>
            {isTabletOrMobileDevice ? (
              <Img
                src={event.eventPhotoURL}
                loader={<LoadingComponent />}
                unloader={<span>Image not found</span>}
                style={{ width: '99%', marginBottom: '1em' }}
              />
            ) : (
              <Item.Image size="medium" src={event.eventPhotoURL} />
            )}

            <Item.Content>
              <Item.Header
                as={Link}
                to={`events/${event.id}`}
                style={
                  !isTabletOrMobileDevice
                    ? { marginTop: '0.6em' }
                    : { marginTop: 0 }
                }
              >
                {event.title}
              </Item.Header>
              <Item.Description>
                Hosted by{' '}
                <Link to={`/profile/${event.hostUid}`}>{event.hostedBy}</Link>
              </Item.Description>
              {event.cancelled && (
                <Label
                  style={{ top: '-40px' }}
                  ribbon="right"
                  color="red"
                  content="This event has been cancelled"
                />
              )}

              {!event.cancelled && event.finished && (
                <Label
                  style={{ top: '-40px' }}
                  ribbon="right"
                  color="grey"
                  content="Finished"
                />
              )}
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
      <Segment>
        <span>
          <Icon name="clock" />{' '}
          {format(event.date && event.date.toDate(), 'EEEE, dd LLLL')} at{' '}
          {format(event.date && event.date.toDate(), 'H:mm a')} |{' '}
          <Icon name="marker" /> {event.venue}
        </span>
      </Segment>
      <Segment secondary>
        <List horizontal>
          {event.attendees &&
            joinAttendees.map(attendee => (
              <LazyLoading key={attendee.id} height={85}>
                <EventListAttendee attendee={attendee} />
              </LazyLoading>
            ))}
        </List>
      </Segment>
      <Segment clearing>
        <span>{event.description}</span>
        <Button
          as={Link}
          to={`/events/${event.id}`}
          color="teal"
          floated="right"
          content="View"
        />
      </Segment>
    </Segment.Group>
  );
};

export default EventListItem;
