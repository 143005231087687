import React, { useRef, Fragment } from "react";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
import { Button } from "semantic-ui-react";

const PrintEventOrder = ({ attendees, event, auth }) => {
  const componentRef = useRef();
  return (
    <div>
      {event.hostUid === auth.uid && event.category === "commercial" && (
        <Fragment>
          <ReactToPrint
            trigger={() => (
              <Button size="small">
                Print Attendees
              </Button>
            )}
            content={() => componentRef.current}
          />
          <div style={{ display: "none" }}>
            <ComponentToPrint
              attendees={attendees}
              event={event}
              ref={componentRef}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PrintEventOrder;
