import React, { useMemo, Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import EventDetailedHeader from "./EventDetailedHeader";
import EventDetailedInfo from "./EventDetailedInfo";
import EventDetailedChat from "./EventDetailedChat";
import EventDetailedSidebar from "./EventDetailedSidebar";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { objectToArray } from "../../../app/common/util/helpers";
import NotFound from "../../../app/layout/NotFound";
import { useMediaQuery } from "react-responsive";

const EventDetailedPage = ({ match: { params } }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  useFirestoreConnect(`events/${params.id}`);
  const eventPhotosQuery = useMemo(
    () => ({
      collection: "events",
      doc: params.id,
      subcollections: [{ collection: "eventPhotos" }],
      storeAs: "photos"
    }),
    [params.id]
  );
  useFirestoreConnect(eventPhotosQuery);
  const photos = useSelector(
    state =>
      (state.firestore.ordered.photos && state.firestore.ordered.photos) || [],
    []
  );
  const event = useSelector(
    state =>
      (state.firestore.ordered.events &&
        state.firestore.ordered.events.filter(e => e.id === params.id)[0]) ||
      {},
    []
  );

  const auth = useSelector(state => state.firebase.auth, []);

  const attendees = event && event.attendees && objectToArray(event.attendees);

  const notHostingAttendees =
    event &&
    event.attendees &&
    objectToArray(event.attendees)
      .filter(
        attendee => attendee.id !== event.hostUid && attendee.going === true
      )
      .sort((a, b) =>
        a.displayName.toLowerCase() > b.displayName.toLowerCase()
          ? 1
          : b.displayName.toLowerCase() > a.displayName.toLowerCase()
          ? -1
          : 0
      );

  const attendeesList =
    event &&
    event.attendees &&
    objectToArray(event.attendees)
      .filter(attendee => attendee.id === event.hostUid)
      .concat(notHostingAttendees);

  const isHost = event && event.hostUid === auth.uid;

  const isGoing = attendees && attendees.filter(a => a.id === auth.uid)[0];
  const authenticated = auth.isLoaded && !auth.isEmpty;
  const loadingEvent = useSelector(
    state => state.firestore.status.requesting[`events/${params.id}`],
    []
  );
  const attendee = attendees && attendees.filter(a => a.id === auth.uid)[0];
  if (loadingEvent) return <LoadingComponent />;
  if (Object.keys(event).length === 0) return <NotFound />;

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid>
          <Grid.Column width={16}>
            <EventDetailedHeader
              attendee={attendee}
              attendeeStatus={attendee && attendee.status}
              event={event}
              isHost={isHost}
              isGoing={isGoing && isGoing.going}
              authenticated={authenticated}
              photos={photos}
            />
            <EventDetailedSidebar
              event={event}
              attendees={attendeesList}
              auth={auth}
            />
            <EventDetailedInfo event={event} />
            {authenticated && <EventDetailedChat event={event} auth={auth} />}
          </Grid.Column>
        </Grid>
      )}
      {isDesktopOrLaptop && (
        <Grid>
          <Grid.Column width={10}>
            <EventDetailedHeader
              attendee={attendee}
              attendeeStatus={attendee && attendee.status}
              event={event}
              isHost={isHost}
              isGoing={isGoing && isGoing.going}
              authenticated={authenticated}
              photos={photos}
            />
            <EventDetailedInfo event={event} />
            {authenticated && <EventDetailedChat event={event} auth={auth} />}
          </Grid.Column>
          <Grid.Column width={6}>
            <EventDetailedSidebar
              event={event}
              attendees={attendeesList}
              auth={auth}
            />
          </Grid.Column>
        </Grid>
      )}
    </Fragment>
  );
};

export default EventDetailedPage;
