import React, { Fragment } from 'react';
import { Menu, Container } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { NavLink, Link, withRouter } from 'react-router-dom';
import { useFirebase } from 'react-redux-firebase';
import SignedOutMenu from '../Menus/SignedOutMenu';
import SignedInMenu from '../Menus/SignedInMenu';
import { useMediaQuery } from 'react-responsive';

const NavBar = ({ history }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const isMobile = useMediaQuery({
    query: '(max-device-width: 400px)'
  });
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth, []);

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/');
      });
  };

  const authenticated = auth.isLoaded && !auth.isEmpty;
  return (
    <Menu inverted fixed="top">
      <Container>
        <Menu.Item as={Link} to="/" header>
          <img src="/assets/logo.png" alt="logo" />
          <span
            style={
              isMobile ? { marginLeft: '-0.9em' } : { marginLeft: '-0.4em' }
            }
          >
            {' '}
            DwiZ-Vent
          </span>
        </Menu.Item>
        <Menu.Item as={NavLink} exact to="/events" name="Events" />
        {authenticated && isDesktopOrLaptop && (
          <Fragment>
            <Menu.Item as={NavLink} to="/people" name="People" />
          </Fragment>
        )}

        {authenticated ? (
          <SignedInMenu signOut={handleLogout} />
        ) : (
          <SignedOutMenu />
        )}
      </Container>
    </Menu>
  );
};

export default withRouter(NavBar);
