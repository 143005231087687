import React, { useMemo, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFirebase,
  useFirestore,
  useFirestoreConnect,
  isEmpty
} from 'react-redux-firebase';
import { Button, Grid, Segment, Label } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { unfollowUser, followerUser } from '../userActions';
import { useMediaQuery } from 'react-responsive';

const UserDetailedSidebar = ({ isCurrentUser, match, profile }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1000px)'
  });
  const firebase = useFirebase();
  const firestore = useFirestore();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.firebase.auth, []);
  const loading = useSelector(state => state.async.loading, []);
  const elementName = useSelector(state => state.async.elementName, []);
  const followingQuery = useMemo(
    () => ({
      collection: 'users',
      doc: auth.uid,
      subcollections: [{ collection: 'following', doc: match.params.id }],
      storeAs: 'following'
    }),
    [auth.uid, match.params.id]
  );
  const totalFollowers = useMemo(
    () => ({
      collection: 'users',
      doc: match.params.id,
      subcollections: [{ collection: 'followers' }],
      storeAs: 'totalFollowers'
    }),
    [match.params.id]
  );
  const totalFollowing = useMemo(
    () => ({
      collection: 'users',
      doc: match.params.id,
      subcollections: [{ collection: 'following' }],
      storeAs: 'totalFollowing'
    }),
    [match.params.id]
  );
  useFirestoreConnect(followingQuery);
  useFirestoreConnect(totalFollowers);
  useFirestoreConnect(totalFollowing);

  const following = useSelector(
    state =>
      state.firestore.ordered.following && state.firestore.ordered.following
  );
  const totalFollowingLength = useSelector(
    state =>
      state.firestore.ordered.totalFollowing &&
      state.firestore.ordered.totalFollowing.length
  );
  const totalFollowersLength = useSelector(
    state =>
      state.firestore.ordered.totalFollowers &&
      state.firestore.ordered.totalFollowers.length
  );

  const isFollowing = !isEmpty(following);

  const followUser = useCallback(async () => {
    try {
      await dispatch(
        followerUser({ firebase, firestore }, profile, 'followButton')
      );
    } catch (error) {
      console.log(error);
    }
  }, [firebase, firestore, dispatch, profile]);
  const unFollowUser = useCallback(async () => {
    try {
      await dispatch(
        unfollowUser({ firebase, firestore }, profile, 'unFollowButton')
      );
    } catch (error) {
      console.log(error);
    }
  }, [firebase, firestore, dispatch, profile]);

  return (
    <Fragment>
      {isTabletOrMobileDevice && (
        <Grid.Column width={16} style={{ marginTop: '-28px' }}>
          <Segment>
            <Grid
              textAlign="center"
              verticalAlign="middle"
              style={{ marginBottom: '5px' }}
            >
              <Grid.Column width={6}>
                <Label as="a" color="teal" tag>
                  Following{' '}
                  <div style={{ marginTop: '2px' }}>{totalFollowingLength}</div>
                </Label>
              </Grid.Column>
              <Grid.Column width={6}>
                <Label as="a" color="orange" tag>
                  Followers{' '}
                  <div style={{ marginTop: '2px' }}>{totalFollowersLength}</div>
                </Label>
              </Grid.Column>
            </Grid>

            {isCurrentUser && (
              <Button
                as={Link}
                to="/settings"
                color="teal"
                fluid
                basic
                content="Edit Profile"
              />
            )}
            {!isCurrentUser && !isFollowing && (
              <Button
                name="followButton"
                loading={loading && elementName === 'followButton'}
                disabled={loading}
                onClick={followUser}
                color="teal"
                fluid
                basic
                content="Follow User"
              />
            )}

            {!isCurrentUser && isFollowing && (
              <Button
                name="unFollowButton"
                loading={loading && elementName === 'unFollowButton'}
                disabled={loading}
                onClick={unFollowUser}
                color="teal"
                fluid
                basic
                content="Unfollow"
              />
            )}
          </Segment>
        </Grid.Column>
      )}

      {isDesktopOrLaptop && (
        <Grid.Column width={4}>
          <Segment>
            <Grid
              textAlign="center"
              verticalAlign="middle"
              style={{ marginBottom: '5px' }}
            >
              <Grid.Column width={8}>
                <Label as="a" color="teal" tag>
                  Following{' '}
                  <div style={{ marginTop: '2px' }}>{totalFollowingLength}</div>
                </Label>
              </Grid.Column>
              <Grid.Column width={8}>
                <Label as="a" color="orange" tag>
                  Followers{' '}
                  <div style={{ marginTop: '2px' }}>{totalFollowersLength}</div>
                </Label>
              </Grid.Column>
            </Grid>

            {isCurrentUser && (
              <Button
                as={Link}
                to="/settings"
                color="teal"
                fluid
                basic
                content="Edit Profile"
              />
            )}
            {!isCurrentUser && !isFollowing && (
              <Button
                name="followButton"
                loading={loading && elementName === 'followButton'}
                disabled={loading}
                onClick={followUser}
                color="teal"
                fluid
                basic
                content="Follow User"
              />
            )}

            {!isCurrentUser && isFollowing && (
              <Button
                name="unFollowButton"
                loading={loading && elementName === 'unFollowButton'}
                disabled={loading}
                onClick={unFollowUser}
                color="teal"
                fluid
                basic
                content="Unfollow"
              />
            )}
          </Segment>
        </Grid.Column>
      )}
    </Fragment>
  );
};

export default withRouter(UserDetailedSidebar);
