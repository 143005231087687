import React, { useCallback, useMemo, Fragment } from 'react';
import { Header, Segment, Feed } from 'semantic-ui-react';
import LazyLoad from 'react-lazyload';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import EventActivityItem from './EventActivityItem';

const EventActivity = ({ contextRef }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1001px)'
  });
  const query = useMemo(
    () => ({
      collection: 'activity',
      orderBy: ['timestamp', 'desc'],
      limit: 15,
      storeAs: 'activity'
    }),
    []
  );
  useFirestoreConnect(query);
  // useSelector case, to memoize a selector.  This ensure the useSelector will have exactly same selector every time the component re-render
  const activitySelector = useCallback(
    state => state.firestore.ordered.activity,
    []
  );
  const activities = useSelector(activitySelector);

  return (
    <Fragment>
      {activities && activities.length > 0 && (
        <div
          style={
            isDesktopOrLaptop
              ? {
                  position: 'fixed',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  height: '400px',
                  width: '360px',
                  zIndex: 0
                }
              : {
                  marginTop: '-8px',
                  zIndex: 0
                }
          }
        >
          {' '}
          <Header attached="top" content="Recent Activity" />
          <Segment attached>
            <Feed>
              {isDesktopOrLaptop && activities
                ? activities.map(activity => (
                    <EventActivityItem key={activity.id} activity={activity} />
                  ))
                : activities
                ? activities.map(activity => (
                    <LazyLoad key={activity.id} height={100}>
                      <EventActivityItem activity={activity} />
                    </LazyLoad>
                  ))
                : 'No Activity'}
            </Feed>
          </Segment>
        </div>
      )}
    </Fragment>
  );
};

export default EventActivity;
