import React, { useCallback } from 'react';
import { Modal } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { reset } from 'redux-form';

import { closeModal } from '../modals/modalActions';
import UserGoingToEvent from '../user/UserGoingToEvent/UserGoingToEvent';

const GoingToEventModal = event => {
  const dispatch = useDispatch();
  const closeModalReset = useCallback(() => {
    dispatch(reset('userGoingToEventForm'));
    dispatch(closeModal());
  }, [dispatch]);
  return (
    <Modal size="mini" open={true} onClose={closeModalReset} closeIcon>
      <Modal.Header style={{ color: 'orangeRed' }}>
        Join to {event.title}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <UserGoingToEvent event={event} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default GoingToEventModal;
