import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import GoogleMapReact from 'google-map-react';

const Marker = () => <Icon name="marker" size="big" color="red" />;

const EventDetailedMap = ({ lat, lng }) => {
  const center = [lat, lng];
  const zoom = 16;
  return (
    <Segment attached="bottom" style={{ padding: 0 }}>
      <div style={{ height: '310px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBIBaGHoqRMN0Tm8lctP61cGGrDEptlgpw' }}
          defaultCenter={center}
          defaultZoom={zoom}
          layerTypes={['TransitLayer']}
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
    </Segment>
  );
};

export default EventDetailedMap;
