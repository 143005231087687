import React, { Fragment } from "react";
import { Grid } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import SettingsNav from "./SettingsNav";
import BasicPage from "./BasicPage";
import AboutPage from "./AboutPage";
import PhotosPage from "./Photos/PhotosPage";
import AccountPage from "./AccountPage";
import { useMediaQuery } from "react-responsive";

const SettingsDashboard = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1001px)"
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 1000px)"
  });
  const profile = useSelector(state => state.firebase.profile);
  return (
    <Grid>
      {isTabletOrMobileDevice && (
        <Fragment>
          <Grid.Column width={16}>
            <SettingsNav />
          </Grid.Column>
          <Grid.Column width={16}>
            <Switch>
              <Redirect exact from="/settings" to="/settings/basic" />
              <Route
                path="/settings/basic"
                render={() => <BasicPage initialValues={profile} />}
              />
              <Route
                path="/settings/about"
                render={() => <AboutPage initialValues={profile} />}
              />
              <Route path="/settings/photos" component={PhotosPage} />
              <Route path="/settings/account" component={AccountPage} />
            </Switch>
          </Grid.Column>
        </Fragment>
      )}
      {isDesktopOrLaptop && (
        <Fragment>
          <Grid.Column width={12}>
            <Switch>
              <Redirect exact from="/settings" to="/settings/basic" />
              <Route
                path="/settings/basic"
                render={() => <BasicPage initialValues={profile} />}
              />
              <Route
                path="/settings/about"
                render={() => <AboutPage initialValues={profile} />}
              />
              <Route path="/settings/photos" component={PhotosPage} />
              <Route path="/settings/account" component={AccountPage} />
            </Switch>
          </Grid.Column>
          <Grid.Column width={4}>
            <SettingsNav />
          </Grid.Column>
        </Fragment>
      )}
    </Grid>
  );
};

export default SettingsDashboard;
