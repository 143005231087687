import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBIBaGHoqRMN0Tm8lctP61cGGrDEptlgpw",
  authDomain: "cakesshop-41792.firebaseapp.com",
  databaseURL: "https://cakesshop-41792.firebaseio.com",
  projectId: "cakesshop-41792",
  storageBucket: "cakesshop-41792.appspot.com",
  messagingSenderId: "644087039751",
  appId: "1:644087039751:web:ae34d833e718835ba067a2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
